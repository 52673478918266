export const EMDESENVOLVIMENTO: boolean = false

export const VERSAO_SISTEMA = '1.23'

export const PORT_ENDPOINT: number = 4002

export const URL_ENDPOINT: string = EMDESENVOLVIMENTO ? 'localhost' : 'app.poupeaquibrasil.com.br'

export const URL_CLIENT: string = 'http'.concat( EMDESENVOLVIMENTO ? '://' : 's://' ).concat( URL_ENDPOINT ).concat( EMDESENVOLVIMENTO ? ':8080/' : '/' )

export const ENDPOINT_GRAPHQL: string = 'http'.concat( EMDESENVOLVIMENTO ? '://' : 's://' ).concat( URL_ENDPOINT ).concat( ':' ).concat( PORT_ENDPOINT.toString() ).concat( '/graphql' )

export const ENDPOINT: string = 'http'.concat( EMDESENVOLVIMENTO ? '://' : 's://' ).concat( URL_ENDPOINT ).concat( ':' ).concat( PORT_ENDPOINT.toString() ).concat( '/' )

export const TAMANHO_MAXIMO_SELFIE = 20000 * 1024
