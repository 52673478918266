import React, { useContext, useState, useEffect } from 'react'
import { Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import InputText from '../../DevComponents/InputText'
import BackEndAPI from '../../Services/BackEndAPI'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import { MensagemTipo } from '../../GlobalStates/MensagemState'

import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable'
import { useNavigate } from 'react-router-dom'
import ClsValidacao from '../../Utils/ClsValidacao'
import ClsFormatacao from '../../Utils/ClsFormatacao'
import { ContaCorrenteInterface } from '../../ImportBackend/Interfaces/ContaCorrenteInterfaces'
import SelectMultiplo from '../../DevComponents/SelectMultiplo'
import { UnidadeInterface } from '../../ImportBackend/Interfaces/UnidadeInterfaces'
import ComboBox from '../../DevComponents/ComboBox'

interface PesquisaInterface {
  dataInicial: string
  dataFinal: string
  idUnidade: number
}

export default function RelContaCorrente () {

  const clsFormatacao: ClsFormatacao = new ClsFormatacao()

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'dataMovimentacao',
      cabecalho: 'Data',
      alinhamento: 'left',
      format: ( v ) => clsFormatacao.dataISOtoUser( v )
    },
    {
      campo: 'descricao',
      cabecalho: 'Descrição',
      alinhamento: 'left'
    },
    {
      campo: 'Unidade',
      cabecalho: 'Unidade',
      alinhamento: 'left',
      format: ( v ) => v.descricao
    },
    {
      campo: 'debito',
      cabecalho: 'Débito',
      alinhamento: 'right',
      format: ( v: number ) => clsFormatacao.currency( v )
    },
    {
      campo: 'credito',
      cabecalho: 'Crédito',
      alinhamento: 'right',
      format: ( v: number ) => clsFormatacao.currency( v )
    },
    {
      campo: 'saldoUnidade',
      cabecalho: 'Saldo',
      alinhamento: 'right',
      format: ( v: number ) => clsFormatacao.currency( v )
    }
  ]

  const clsApi = new BackEndAPI()

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const [errosPesquisa, setErrosPesquisa] = useState( {} )

  const [pesquisa, setPesquisa] = useState<PesquisaInterface>( {
    dataInicial: '',
    dataFinal: '',
    idUnidade: 0
  } )

  const [rsPesquisa, setRsPesquisa] = useState<Array<ContaCorrenteInterface>>( [] )

  const [rsUnidades, setRsUnidades] = useState<Array<UnidadeInterface>>( [] )

  const navigate = useNavigate()

  const btFechar = () => {
    navigate( '/' )
  }

  const validarPesquisa = (): boolean => {
    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.eData( 'dataInicial', pesquisa, erros, retorno, true )
    retorno = clsValidacao.eData( 'dataFinal', pesquisa, erros, retorno, true )

    if ( retorno
      && pesquisa.dataInicial.length > 0
      && pesquisa.dataFinal.length > 0
      && pesquisa.dataInicial > pesquisa.dataFinal
    ) {
      erros.dataInicial = 'Período Inválido!'
      erros.dataFinal = 'Período Inválido!'
      retorno = false
    }

    if ( !pesquisa.idUnidade || pesquisa.idUnidade === 0 ) {
      retorno = false
      erros.idUnidade = "Escolha uma Unidade"
    }

    setErrosPesquisa( erros )

    return retorno
  }

  const onClickPesquisa = () => {

    if ( validarPesquisa() ) {

      const query = `
      getContaCorrente(
        dataInicial: "${pesquisa.dataInicial}",
        dataFinal: "${pesquisa.dataFinal}",
        idUnidade: ${pesquisa.idUnidade})
      {
        idContaCorrente
        idContratoParcela
        idUnidade
        Unidade {
          descricao
        }
        descricao
        debito
        credito
        saldoUnidade
        dataMovimentacao
        dataConciliacao
      }
    `

      clsApi.query<Array<ContaCorrenteInterface>>( query, 'getContaCorrente', 'Pequisando Movimentação...', contexto ).then( rsContaCorrente => {

        setRsPesquisa( rsContaCorrente )

      } ).catch( () => {

        setMensagemState( {
          ...mensagemState,
          titulo: 'Erro! Consulte Suporte!',
          exibir: true,
          mensagem: 'Erro ao Consultar ContaCorrente!',
          tipo: MensagemTipo.Error,
          exibirBotao: true
        } )

      } )

    }

  }

  const abortController: AbortController = new AbortController()

  const pesquisarUsuarioUnidades = () => {
    const query = `
      getUsuarioUnidadesPermitidasPorAcesso {
        idUnidade
        descricao
      }
    `

    clsApi.query<Array<UnidadeInterface>>( query, 'getUsuarioUnidadesPermitidasPorAcesso', 'Recebendo Unidades...', contexto, abortController ).then( rsUnidades => {

      setRsUnidades( rsUnidades )

      if ( rsUnidades.length === 1 ) {
        setPesquisa( { ...pesquisa, idUnidade: rsUnidades[0].idUnidade as number } )
      }

    } ).catch( () => {

      setMensagemState( {
        ...mensagemState,
        titulo: 'Erro! Consulte Suporte!',
        exibir: true,
        mensagem: 'Erro ao Consultar Unidades de Usuários!',
        tipo: MensagemTipo.Error,
        exibirBotao: true
      } )

    } )
  }

  useEffect( () => {
    pesquisarUsuarioUnidades()

    return () => {

      abortController.abort()

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'center' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Extrato de Conta Corrente
                <Typography variant="body2" gutterBottom>
                  Consulta Movimentação Detalhada de Conta Corrente
                </Typography>
              </Typography>

              <IconButton onClick={() => btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Grid item xs={12} sx={{ mb: 3 }}>

              <Grid container sx={{ display: 'flex', alignItems: 'center' }}>

                <Grid item xs={12} >

                  <ComboBox
                    campoDescricao='descricao'
                    campoID='idUnidade'
                    opcoes={rsUnidades}
                    dados={pesquisa}
                    field='idUnidade'
                    label='Acessar Dados de:'
                    setState={setPesquisa}
                    erros={errosPesquisa}
                  />

                </Grid>

                <Grid item xs={12} sm={6}>

                  <InputText
                    dados={pesquisa}
                    field='dataInicial'
                    label='Início'
                    setState={setPesquisa}
                    erros={errosPesquisa}
                    tipo='date'
                    mask='00/00/0000'
                    textAlign="right"
                  />

                </Grid>

                <Grid item xs={12} sm={6} sx={{ pl: { sm: 1 } }}>

                  <InputText
                    dados={pesquisa}
                    field='dataFinal'
                    label='Término'
                    setState={setPesquisa}
                    erros={errosPesquisa}
                    tipo='date'
                    mask='00/00/0000'
                    textAlign="right"
                  />

                </Grid>

                <Grid item xs={12} sx={{ textAlign: 'right', mt: 3 }}>
                  <Button variant='contained' onClick={() => onClickPesquisa()}>Pesquisar</Button>
                </Grid>

              </Grid>

            </Grid>

            <Grid item xs={12} sx={{ mt: 3 }}>
              <DataTable dados={rsPesquisa} cabecalho={Cabecalho} />
            </Grid>

          </Grid>
        </Paper>

      </Container>
    </>
  )
}