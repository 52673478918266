/*
interface SISTEMA_PERMISSOES_INTERFACE {
  [key: string]: {
    MODULO: string
    PERMISSOES: {
      [key: string]: string
      // EXCLUIR: string,

    }
  }
}
*/

// TODO - Criar Novo Crud

export interface SISTEMA_PERMISSOES_INTERFACE {

  CANCELAMENTOS: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  },

  CONTRATOS: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  },

  RELATORIOS: {
    MODULO: string
    PERMISSOES: {
      CONTRATOS: string
      SALDOUNIDADE: string
      PRODUTIVIDADE: string
      CONTACORRENTE: string
      CORA: string
      MOVIMENTACAOFINANCEIRA: string
      PARCELAS: string
      CONSULTARSALDOGERAL: string
    }
  },

  CONTACORRENTE: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  },

  CLIENTES: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  },

  UNIDADES: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  },

  REGIONAIS: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  },

  PRODUTOS: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  },

  GRUPOS: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  },

  USUARIOS: {
    MODULO: string
    PERMISSOES: {
      USUARIOS_GLOBAIS: string
      CADASTRO_USUARIO_UNIDADE: string
    }
  }
}

export const MAXIMO_ERRO_LOGIN: number = 3

export const SISTEMA_PERMISSOES: SISTEMA_PERMISSOES_INTERFACE = {

  CANCELAMENTOS: {
    MODULO: 'Cancelamentos Realizados',
    PERMISSOES: {
      MANUTENCAO: 'Edição de Cancelamentos Realizados'
    }
  },

  CONTRATOS: {
    MODULO: 'Contratos',
    PERMISSOES: {
      MANUTENCAO: 'Pesquisa e Manutenção de Contratos'
    }
  },

  RELATORIOS: {
    MODULO: 'Relatórios',
    PERMISSOES: {
      CONTRATOS: 'Contratos Emitidos',
      SALDOUNIDADE: 'Saldos das Unidades',
      PRODUTIVIDADE: 'Produtividade',
      CONTACORRENTE: 'Extrado de Conta Corrente',
      CORA: 'Base de Dados Cora',
      MOVIMENTACAOFINANCEIRA: 'Movimento Financeiro',
      PARCELAS: 'Relatório Analítico de Parcelas',
      CONSULTARSALDOGERAL: 'Visualizar Saldo Geral e Saldo Cora'
    }
  },

  CLIENTES: {
    MODULO: 'Cadastro de Clientes',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Clientes'
    }
  },

  CONTACORRENTE: {
    MODULO: 'Conta Corrente',
    PERMISSOES: {
      MANUTENCAO: 'Movimentar Conta Corrente'
    }
  },

  UNIDADES: {
    MODULO: 'Cadastro de Unidades',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Unidades'
    }
  },

  REGIONAIS: {
    MODULO: 'Cadastro de Regionais',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Regionais'
    }
  },

  PRODUTOS: {
    MODULO: 'Cadastro de Produtos',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Produtos'
    }
  },

  GRUPOS: {
    MODULO: 'Cadastro de Grupos de Usuários',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Grupos'
    }
  },

  USUARIOS: {
    MODULO: 'Cadastro de Usuário',
    PERMISSOES: {
      USUARIOS_GLOBAIS: 'Manutenção de Usuários Globais',
      CADASTRO_USUARIO_UNIDADE: 'Manutenção de Usuários da Unidade'
    }
  }
}
