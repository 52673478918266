import React, { useEffect, useContext, useState } from 'react'
import { Button, Container, Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material'
import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import { ContratoInterface } from '../../ImportBackend/Interfaces/ContratoInterfaces'
import BackEndAPI from '../../Services/BackEndAPI'
import Condicional from '../../Layout/Condicional'

import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import PesquisarTabela from '../../DevComponents/PesquisarTabela'
import InputText from '../../DevComponents/InputText'
import ClsValidacao from '../../Utils/ClsValidacao'
import { DateTime } from 'luxon'
import { clsUtils } from 'zlib-utils'
import { RespostaPadraoInterface } from '../../ImportBackend/Interfaces/PadraoInterfaces'
import { MensagemTipo } from '../../GlobalStates/MensagemState'
import ClientesContratosArquivos from './ClientesContratosArquivos'
import { StatusContratoType, StatusContratoTypes } from '../../ImportBackend/types/ConstantesDataTypes'
import ClientesContratosParcelasView from './ClientesContratosParcelasView'
import ClsFormatacao from '../../Utils/ClsFormatacao'
import ClientesCancelarContrato from './ClientesCancelarContrato'

interface VencimentosInterface {
  idVencimento: number,
  vencimento: string
}

enum StatusForm {
  NOVOCONTRATO,
  EXIBINDO,
  INICIAL,
  CANCELANDO
}

interface PropsInterface {
  idCliente: number | undefined
  onCancelar: () => void
}

export default function ClientesContratos ( { idCliente, onCancelar }: PropsInterface ) {

  const [statusForm, setStatusForm] = useState<StatusForm>( StatusForm.INICIAL )

  const TituloForm = {
    [StatusForm.INICIAL]: 'Contratos Emitidos',
    [StatusForm.NOVOCONTRATO]: 'Emitir Novo Contrato',
    [StatusForm.EXIBINDO]: 'Contrato Emitido',
    [StatusForm.CANCELANDO]: 'Cancelamento de Contrato Emitido',
  }

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { loginState } = contexto
  const { mensagemState, setMensagemState } = contexto

  const theme = useTheme()

  //  const { loginState } = contexto
  //  const { mensagemState, setMensagemState } = contexto

  const [rsContratos, setRsContratos] = useState<Array<ContratoInterface>>( [] )

  const [refresh, setRefresh] = useState( 0 )

  const [isCoraOnLine, setIsCoraOnLine] = useState( false )

  const statusCora = () => {
    const query: string = `
      StatusCora {
        ok
        mensagem
      }
    `

    const clsApi = new BackEndAPI()

    clsApi.query<RespostaPadraoInterface>( query, 'StatusCora', 'Checando Status Cora...', contexto ).then( rs => {
      setIsCoraOnLine( rs.ok )
    } )

  }


  useEffect( () => {

    statusCora()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  useEffect( () => {
    const abortController: AbortController = new AbortController()

    const clsApi = new BackEndAPI()

    const query: string = `
      getContratosPorIdCliente(idCliente: ${idCliente}) {
        idContrato
        idCliente
        idUnidade
        idProduto
        valorTotal
        primeiroVencimento
        parcelas
        vrDesconto
        perJuros
        status
        createdAt
        updatedAt
        Parcelas {
          idContratoParcela
          valor
          vencimento
          status
          dataRecebimento
          vrRecebido
          vrDescontoRecebido
          vrJurosRecebido
          vrMultaRecebido
        }
        cpfResponsavelPreenchimento
        nomeResponsavelPreenchimento
      }
    `

    clsApi.query<Array<ContratoInterface>>( query, 'getContratosPorIdCliente', 'Pesquisando Contratos...', contexto, abortController ).then( rs => {
      setRsContratos( rs )
    } )

    return () => {

      abortController.abort()

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCliente, refresh] )

  const CabecalhoVencimentos: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'vencimento',
      cabecalho: 'Vencimento',
      alinhamento: 'left',
      format: ( v: string ) => clsFormatacao.dataISOtoUser( v )
    },
  ]

  const clsFormatacao: ClsFormatacao = new ClsFormatacao()

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'idContrato',
      cabecalho: 'Contrato',
      alinhamento: 'left',
      format: ( v: number ) => '000000'.concat( v.toString() ).slice( -6 )
    },
    {
      campo: 'valorTotal',
      cabecalho: 'Vr. Total',
      alinhamento: 'right',
      format: ( v: number ) => clsFormatacao.currency( v )
    },
    {
      campo: 'primeiroVencimento',
      cabecalho: '1o. Vencimento',
      alinhamento: 'left',
      format: ( v: string ) => clsFormatacao.dataISOtoUser( v )
    },
    {
      campo: 'parcelas',
      cabecalho: 'Parcelas',
      alinhamento: 'left'
    },
    {
      campo: 'vrDesconto',
      cabecalho: 'Desconto',
      alinhamento: 'left',
      format: ( v: number ) => clsFormatacao.currency( v )
    },
    {
      campo: 'status',
      cabecalho: 'Status',
      alinhamento: 'left',
      format: ( v: string ) => StatusContratoTypes.find( ( e ) => e.idStatus === v )?.descricao
    }
    /*,
    {
      campo: 'status',
      cabecalho: 'Emissao',
      alinhamento: 'left',
      format: ( _v: string, rs: any ) => DateTime.fromISO( rs.createdAt ).toJSON()
    },
    {
      campo: 'status',
      cabecalho: 'Diferença',
      alinhamento: 'left',
      format: ( _v: string, rs: any ) => DateTime.now().diff( DateTime.fromISO( rs.createdAt ) ).toMillis() / 1000 / 60
    }
    */
  ]

  const btNovoContrato = () => {
    setStatusForm( StatusForm.NOVOCONTRATO )
  }

  const ResetDados: ContratoInterface = {
    idContrato: 0,
    idCliente: idCliente ? idCliente : 0,
    idUnidade: loginState.idUnidadeAtual,
    idProduto: 0,
    valorTotal: 0,
    primeiroVencimento: '',
    parcelas: 0,
    vrDesconto: 0,
    perJuros: 0,
    cpfResponsavelPreenchimento: '',
    nomeResponsavelPreenchimento: ''
  }

  const [dados, setDados] = useState<ContratoInterface>( ResetDados )

  const [erros, setErros] = useState( {} )

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'idUnidade', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'idProduto', dados, erros, retorno )
    retorno = clsValidacao.eData( 'primeiroVencimento', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'valorTotal', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'parcelas', dados, erros, retorno )
    retorno = clsValidacao.eCPF( 'cpfResponsavelPreenchimento', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'nomeResponsavelPreenchimento', dados, erros, retorno )

    if ( retorno ) {

      if ( dados.primeiroVencimento < DateTime.now().toFormat( 'yyyy-MM-dd' ) ) {
        erros.primeiroVencimento = 'Data Inválida!'
        retorno = false
      }

      // console.log( 'Tipo de Parcelas', typeof dados.parcelas )

      if ( dados.parcelas > 24 ) {
        erros.parcelas = 'Máximo de 24 parcelas para emissão!'
        retorno = false
      }

    }

    setErros( erros )

    return retorno

  }

  const btEmitirContrato = () => {

    if ( validarDados() ) {

      const clsApi: BackEndAPI = new BackEndAPI()

      const mutation: string = `
        emitirContrato(dados: ${clsUtils.ConverterEmGql( dados as Object )}, 
                       vencimentos: ${clsUtils.ConverterEmGql( rsVencimentos.map( d => d.vencimento ) )}
        ) {
          ok
          mensagem
        }
      `

      clsApi.mutation<RespostaPadraoInterface>( mutation, 'emitirContrato', 'Emitindo Contrato', contexto ).then( rs => {
        if ( !rs.ok ) {
          setMensagemState( {
            ...mensagemState,
            titulo: 'Erro ao Emitir Contrato!!!',
            exibir: true,
            mensagem: 'Contrato Não Emitido! Verifique os dados e/ou contacte o suporte!',
            tipo: MensagemTipo.Error,
            exibirBotao: true
          } )
        } else {
          setStatusForm( StatusForm.INICIAL )
          setRefresh( refresh + 1 )
        }
      } )

    }

  }

  const btCancelarContrato = ( rs: ContratoInterface ) => {
    setDados( rs )
    setStatusForm( StatusForm.CANCELANDO )
  }

  const onCancelarContratoCancelar = () => {
    // setDados( ResetDados )
    // setStatusForm( StatusForm.INICIAL )
    onCancelar()
  }

  const btExibirContrato = ( rs: ContratoInterface ) => {
    setDados( rs )
    setStatusForm( StatusForm.EXIBINDO )
  }

  const btDownloadContrato = ( rs: ContratoInterface ) => {

    const clsApi: BackEndAPI = new BackEndAPI()

    const query: string = `
        downloadContrato(idContrato: ${rs.idContrato} )
    `

    clsApi.query<String>( query, 'downloadContrato', 'Downloading Contrato...', contexto ).then( rsDownload => {
      let link = document.createElement( 'a' )
      link.href = 'data:application/octet-stream;base64,' + rsDownload;
      link.download = "Contrato ".concat( " - " ).concat( '000000'.concat( ( rs.idContrato as number ).toString() ).slice( -6 ) ).concat( ".pdf" )
      link.click()
    } )
  }

  const btDownloadParcelas = ( rs: ContratoInterface ) => {

    const clsApi: BackEndAPI = new BackEndAPI()

    const query: string = `
        emitirBoletosCora(idContrato: ${rs.idContrato} ) {
          ok
          mensagem
        }
    `

    clsApi.query<RespostaPadraoInterface>( query, 'emitirBoletosCora', 'Downloading Parcelas...', contexto ).then( rsOK => {
      if ( rsOK.ok ) {

        if ( rsOK.mensagem.length > 0 ) {
          let link = document.createElement( 'a' )
          link.href = rsOK.mensagem
          link.click()
        } else {
          contexto.setMensagemState( {
            ...contexto.mensagemState,
            exibirBotao: true,
            mensagem: 'Faça o download individual de cada parcela.',
            titulo: 'Erro no Download',
            tipo: MensagemTipo.Error,
            exibir: true
          } )
        }

      } else {
        setMensagemState( {
          ...mensagemState,
          titulo: 'Erro ao Emitir Contrato Junto ao Cora!!!',
          exibir: true,
          mensagem: 'Contrato Não Emitido! Contacte o suporte!',
          tipo: MensagemTipo.Error,
          exibirBotao: true
        } )
      }
    } )
  }

  const btReemitirContrato = ( rs: ContratoInterface ) => {

    const clsApi: BackEndAPI = new BackEndAPI()

    const query: string = `
        reEmitirBoletosCora(idContrato: ${rs.idContrato} ) {
          ok
          mensagem
        }
    `

    clsApi.query<RespostaPadraoInterface>( query, 'reEmitirBoletosCora', 'Downloading Parcelas...', contexto ).then( rsOK => {
      if ( rsOK.ok ) {

        if ( rsOK.mensagem.length > 0 ) {
          let link = document.createElement( 'a' )
          link.href = rsOK.mensagem
          link.click()
        } else {
          contexto.setMensagemState( {
            ...contexto.mensagemState,
            exibirBotao: true,
            mensagem: 'Faça o download individual de cada parcela.',
            titulo: 'Erro no Download',
            tipo: MensagemTipo.Error,
            exibir: true
          } )
        }

      } else {
        setMensagemState( {
          ...mensagemState,
          titulo: 'Erro ao Emitir Contrato Junto ao Cora!!!',
          exibir: true,
          mensagem: 'Contrato Não Emitido! Contacte o suporte!',
          tipo: MensagemTipo.Error,
          exibirBotao: true
        } )
      }
    } ).catch( () => {
      setMensagemState( {
        ...mensagemState,
        titulo: 'Erro ao Emitir Contrato Junto ao Cora!!!',
        exibir: true,
        mensagem: 'Contrato Não Emitido! Verifique no Relatório do Cora!',
        tipo: MensagemTipo.Error,
        exibirBotao: true
      } )
    } )
  }

  const pesquisarCPFPreenchimento = ( cpf: string ) => {
    const clsValidacao: ClsValidacao = new ClsValidacao()

    if (
      clsValidacao.eCPF( 'cpfResponsavelPreenchimento', { cpfResponsavelPreenchimento: cpf }, {}, true )
      // && dados.nomeResponsavelPreenchimento.length === 0 
    ) {

      const clsApi = new BackEndAPI()
      const query: string = `
        pesquisaCpfPreenchimento(cpf: "${cpf}") {
          ok
          mensagem
        }
      `

      clsApi.query<RespostaPadraoInterface>( query, 'pesquisaCpfPreenchimento', 'Pesquisando CPF...', contexto ).then( rs => {
        if ( rs.ok ) {
          setDados( { ...dados, nomeResponsavelPreenchimento: rs.mensagem, cpfResponsavelPreenchimento: cpf } )
        } else {
          setDados( { ...dados, cpfResponsavelPreenchimento: cpf } )
        }
      } )

    } else {
      setDados( { ...dados, cpfResponsavelPreenchimento: cpf } )
    }

  }

  // Rotinas para Controle dos Vencimentos / Datas das Parcelas

  const [rsVencimentos, setRsVencimentos] = useState<Array<VencimentosInterface>>( [] )
  const [exibirBtPersonalizarVencimentos, setExibirBtPersonalizarVencimentos] = useState<boolean>( true )

  const btDatasPersonalizadas = () => {
    setExibirBtPersonalizarVencimentos( !exibirBtPersonalizarVencimentos )
  }

  const calcularVencimentos = ( parcelas: number, primeiroVencimento: string ) => {
    let datas: Array<VencimentosInterface> = []

    if ( parcelas > 0
      && DateTime.fromFormat( primeiroVencimento, 'yyyy-MM-dd' ).isValid ) {

      for ( let contador: number = 1; contador <= parcelas; contador++ ) {
        datas.push( {
          idVencimento: contador,
          vencimento: DateTime.fromFormat( primeiroVencimento, 'yyyy-MM-dd' ).plus( { months: contador - 1 } ).toFormat( 'yyyy-MM-dd' )
        } )
      }
    }

    setRsVencimentos( datas )
  }

  const [vencimento, setVencimento] = useState<VencimentosInterface>( { idVencimento: 0, vencimento: '' } )
  const [errosVencimentos, setErrosVencimentos] = useState( {} )

  const btEditarVencimento = ( rsVencimento: VencimentosInterface ) => {
    setVencimento( rsVencimento )
  }

  const btConfirmarEdicaoVencimento = () => {

    if ( !DateTime.fromFormat( vencimento.vencimento, 'yyyy-MM-dd' ).isValid
      || DateTime.now().toFormat( 'yyyy-MM-dd' ) > vencimento.vencimento
    ) {
      setErrosVencimentos( { vencimento: 'Data Inválida!' } )
    } else {

      let tmpVencimento = rsVencimentos

      if ( vencimento?.idVencimento > 0 )
        tmpVencimento[vencimento.idVencimento as unknown as number - 1] = vencimento

      tmpVencimento.sort( ( p, s ) => p.vencimento > s.vencimento ? 1 : -1 )

      let contador: number = 1
      tmpVencimento.forEach( ( _v, i ) => {
        tmpVencimento[i].idVencimento = contador++
      } )

      setRsVencimentos( tmpVencimento )

      setErrosVencimentos( {} )
      setVencimento( { idVencimento: 0, vencimento: '' } )
    }

  }

  // Final do Controle de Vencimentos

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 1 }}>
          <Grid container sx={{ alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography component="h5" variant="h5" align="left">
                {TituloForm[statusForm]}
              </Typography>
            </Grid>

            <Condicional condicao={[StatusForm.NOVOCONTRATO, StatusForm.EXIBINDO, StatusForm.CANCELANDO].includes( statusForm )}>

              <Grid item xs={12}>

                <PesquisarTabela<any>
                  setState={setDados}
                  field='idUnidade'
                  fieldSet='idUnidade'
                  label='Unidade'
                  dados={dados}
                  campoQueryPesquisaID='idUnidade'
                  campoQueryPesquisa='pesquisa'
                  camposRetornoQueryPesquisa='{idUnidade, descricao}'
                  campoLabelQueryPesquisa='descricao'
                  nomeQueryPesquisa='getUnidades'
                  nomeQueryPesquisaID='getUnidadePorId'
                  mensagemPesquisa='Procurando Unidade...'
                  disabled
                  erros={erros}
                />

              </Grid>

              <Grid item xs={12}>

                <PesquisarTabela<any>
                  setState={setDados}
                  field='idProduto'
                  fieldSet='idProduto'
                  label='Produto'
                  dados={dados}
                  campoQueryPesquisaID='idProduto'
                  campoQueryPesquisa='pesquisa'
                  camposRetornoQueryPesquisa='{idProduto, descricao}'
                  campoLabelQueryPesquisa='descricao'
                  nomeQueryPesquisa='getProdutos'
                  nomeQueryPesquisaID='getProdutoPorId'
                  mensagemPesquisa='Procurando Produto...'
                  disabled={[StatusForm.EXIBINDO, StatusForm.CANCELANDO].includes( statusForm )}
                  erros={erros}
                  pesquisarTudoAoIniciar
                />

              </Grid>

              <Grid item xs={6} sm={4} md={3}>

                <InputText
                  dados={dados}
                  field='primeiroVencimento'
                  label='Primeiro Vencimento'
                  setState={setDados}
                  disabled={[StatusForm.EXIBINDO, StatusForm.CANCELANDO].includes( statusForm )}
                  erros={erros}
                  tipo='date'
                  mask='00/00/0000'
                  textAlign="right"
                  onChange={( v ) => {
                    calcularVencimentos( dados.parcelas, v )
                    setDados( { ...dados, primeiroVencimento: v } )
                  }}
                />

              </Grid>

              <Condicional condicao={useMediaQuery( theme.breakpoints.only( 'sm' ) )}>
                <Grid item sm={8} ></Grid>
              </Condicional>

              <Grid item xs={6} sm={4} md={2} sx={{ pl: { xs: 1, sm: 0, md: 1 } }}>

                <InputText
                  dados={dados}
                  field='valorTotal'
                  label='Vr. Total'
                  setState={setDados}
                  disabled={[StatusForm.EXIBINDO, StatusForm.CANCELANDO].includes( statusForm )}
                  erros={erros}
                  tipo='currency'
                  textAlign="right"
                />

              </Grid>

              <Grid item xs={4} sm={3} md={2} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='parcelas'
                  label='Qtd Parc.'
                  setState={setDados}
                  disabled={[StatusForm.EXIBINDO, StatusForm.CANCELANDO].includes( statusForm )}
                  erros={erros}
                  tipo='number'
                  mask='00'
                  textAlign="right"
                  onChange={( v ) => {
                    calcularVencimentos( v, dados.primeiroVencimento )
                    setDados( { ...dados, parcelas: v } )
                  }}
                />

              </Grid>

              <Grid item xs={4} sm={3} md={3} sx={{ pl: 1 }}>

                <InputText
                  dados={dados}
                  field='vrDesconto'
                  label='Vr. Desc. Parc.'
                  setState={setDados}
                  disabled={[StatusForm.EXIBINDO, StatusForm.CANCELANDO].includes( statusForm )}
                  erros={erros}
                  tipo='currency'
                  textAlign="right"
                />

              </Grid>

              <Grid item xs={4} sm={2} md={2} sx={{ pl: 1 }}>

                <InputText
                  dados={dados}
                  field='perJuros'
                  label='% Juros'
                  setState={setDados}
                  disabled={[StatusForm.EXIBINDO, StatusForm.CANCELANDO].includes( statusForm )}
                  erros={erros}
                  tipo='currency'
                  min={0}
                  max={100}
                  textAlign="right"
                />

              </Grid>

              <Grid item xs={12} sm={4}>

                <InputText
                  dados={dados}
                  field='cpfResponsavelPreenchimento'
                  label='CPF Preenchimento'
                  setState={setDados}
                  disabled={[StatusForm.EXIBINDO, StatusForm.CANCELANDO].includes( statusForm )}
                  erros={erros}
                  maxLength={14}
                  mask='cpf'
                  onChange={( cpf: string ) => pesquisarCPFPreenchimento( cpf )}
                />

              </Grid>

              <Grid item xs={12} sm={8} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='nomeResponsavelPreenchimento'
                  label='Responsável Preenchimento'
                  setState={setDados}
                  disabled={[StatusForm.EXIBINDO, StatusForm.CANCELANDO].includes( statusForm )}
                  erros={erros}
                  maxLength={50}
                  tipo="uppercase"
                />

              </Grid>

              <Condicional condicao={!exibirBtPersonalizarVencimentos}>
                <Condicional condicao={vencimento.vencimento.length > 0}>
                  <Grid item xs={2} sx={{ pl: { sm: 1 } }}>

                    <InputText
                      dados={vencimento}
                      field='vencimento'
                      label='Data Vencimento'
                      setState={setVencimento}
                      erros={errosVencimentos}
                      tipo='date'
                      mask='00/00/0000'
                      textAlign="right"
                    />

                  </Grid>

                  <Grid item xs={2} sx={{ textAlign: 'right', pl: { sm: 1 } }}>
                    <Button variant='contained' startIcon={<CheckIcon />} sx={{ mt: 4, py: 1 }} onClick={() => btConfirmarEdicaoVencimento()}>Confirma</Button>
                  </Grid>

                  <Grid item xs={8}></Grid>
                </Condicional>

                <Grid item xs={12} sm={6} sx={{ mt: 3 }}>
                  <DataTable
                    dados={rsVencimentos}
                    cabecalho={CabecalhoVencimentos}
                    acoes={[
                      { icone: 'create', toolTip: 'Editar Vencimentos', onAcionador: btEditarVencimento },
                    ]}
                  />

                </Grid>

                <Grid item sm={6}>
                </Grid>
              </Condicional>

            </Condicional>

            <Condicional condicao={[StatusForm.INICIAL].includes( statusForm )}>
              <Grid item xs={12} sx={{ mt: 1 }}>
                <DataTable<ContratoInterface>
                  dados={rsContratos}
                  cabecalho={Cabecalho}
                  acoes={[
                    {
                      icone: 'autorenew',
                      toolTip: 'Reemitir',
                      onAcionador: btReemitirContrato,
                      onDisabled: ( v: any ) => ( [StatusContratoType.EMITIDO, StatusContratoType.CANCELADO].includes( v.status as StatusContratoType ) )
                    },
                    {
                      icone: 'grading',
                      toolTip: 'Download Contrato',
                      onAcionador: btDownloadContrato,
                      onDisabled: ( v ) => ![StatusContratoType.EMITIDO].includes( v.status as StatusContratoType )
                    },
                    {
                      icone: 'payments',
                      toolTip: 'Download Parcelas',
                      onAcionador: btDownloadParcelas,
                      onDisabled: ( v ) => ![StatusContratoType.EMITIDO].includes( v.status as StatusContratoType )
                    },
                    {
                      icone: 'visibility',
                      toolTip: 'Exibir Contrato',
                      onAcionador: btExibirContrato,
                      onDisabled: ( v ) => ![StatusContratoType.EMITIDO, StatusContratoType.CANCELADO].includes( v.status as StatusContratoType )
                    },
                    {
                      icone: 'cancel',
                      toolTip: 'Cancelar Contrato',
                      onAcionador: btCancelarContrato,
                      onDisabled: ( v: any ) => {
                        // DateTime.now().toFormat( 'yyyy-MM-dd' )
                        // console.log( v.createdAt,  DateTime.fromISO( v.createdAt ).toFormat( 'yyyy-MM-dd' ) )
                        return (
                          ( ![StatusContratoType.EMITIDO].includes( v.status as StatusContratoType )
                            && DateTime.fromISO( v.createdAt ).toFormat( 'yyyy-MM-dd' ) === DateTime.now().toFormat( 'yyyy-MM-dd' ) )
                          ||
                          [StatusContratoType.CANCELADO].includes( v.status as StatusContratoType )
                        )
                      }
                    }
                  ]}
                />

              </Grid>
            </Condicional>

            <Condicional condicao={[StatusForm.EXIBINDO].includes( statusForm )}>
              <ClientesContratosArquivos idContrato={dados.idContrato} />
            </Condicional>

            <Condicional condicao={[StatusForm.EXIBINDO, StatusForm.CANCELANDO].includes( statusForm )}>
              <ClientesContratosParcelasView parcelas={dados.Parcelas} />
            </Condicional>

            <Condicional condicao={[StatusForm.CANCELANDO].includes( statusForm )}>
              <ClientesCancelarContrato onCancelar={onCancelarContratoCancelar} idContrato={dados.idContrato as number} />
            </Condicional>

            <Condicional condicao={![StatusForm.EXIBINDO, StatusForm.CANCELANDO].includes( statusForm )}>
              <Grid item xs={4} >
                <Button variant='contained' startIcon={<CloseIcon />} sx={{ mt: 2, py: 1 }} onClick={() => onCancelar()}>Cancelar</Button>
              </Grid>
            </Condicional>

            <Condicional condicao={[StatusForm.EXIBINDO].includes( statusForm )}>
              <Grid item xs={12} textAlign='right'>
                <Button variant='contained' startIcon={<CloseIcon />} sx={{ mt: 2, py: 1 }} onClick={() => setStatusForm( StatusForm.INICIAL )}>Cancelar</Button>
              </Grid>
            </Condicional>

            <Condicional condicao={[StatusForm.INICIAL].includes( statusForm ) && isCoraOnLine}>
              <Grid item xs={1} textAlign='right'>
              </Grid>
              <Grid item xs={7} textAlign='right'>
                <Button variant='contained' startIcon={<AddIcon />} sx={{ mt: 2, py: 1 }} onClick={() => btNovoContrato()}>Contrato</Button>
              </Grid>
            </Condicional>

            <Condicional condicao={[StatusForm.NOVOCONTRATO].includes( statusForm ) && exibirBtPersonalizarVencimentos}>
              <Grid item xs={4} textAlign='center'>
                <Button variant='contained' startIcon={<CheckIcon />} sx={{ mt: 2, py: 1 }} onClick={() => btDatasPersonalizadas()}>Datas Personalizadas</Button>
              </Grid>
            </Condicional>

            <Condicional condicao={[StatusForm.NOVOCONTRATO].includes( statusForm )}>
              <Grid item xs={4} textAlign='right'>
                <Button variant='contained' startIcon={<CheckIcon />} sx={{ mt: 2, py: 1 }} onClick={() => btEmitirContrato()}>Emitir</Button>
              </Grid>
            </Condicional>
          </Grid>

        </Paper>
      </Container>

    </>
  )

}