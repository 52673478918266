import React, { useContext, useState } from 'react'
import { Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import InputText from '../../DevComponents/InputText'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import Condicional from '../../Layout/Condicional'
import Button from '@mui/material/Button'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable'
import { useNavigate } from 'react-router-dom'
import { UnidadeInterface } from '../../ImportBackend/Interfaces/UnidadeInterfaces'
import ClsCrud from '../../Utils/ClsCrud'

import { StatusForm } from '../../Utils/ClsCrud'
import ClsValidacao from '../../Utils/ClsValidacao'
import ClsCep from '../../Utils/ClsCep'
import PesquisarTabela from '../../DevComponents/PesquisarTabela'

interface PesquisaInterface {
  descricao: string
}

export default function Unidades () {

  const [statusForm, setStatusForm] = useState<StatusForm>( StatusForm.Pesquisando )

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'descricao',
      cabecalho: 'Descrição',
      alinhamento: 'left'
    },
    {
      campo: 'cidade',
      cabecalho: 'Cidade',
      alinhamento: 'left'
    },
    {
      campo: 'bairro',
      cabecalho: 'Bairro',
      alinhamento: 'left'
    },
    {
      campo: 'endereco',
      cabecalho: 'Endereço',
      alinhamento: 'left'
    }
  ]

  const ResetDados: UnidadeInterface = {
    idUnidade: 0,
    descricao: '',
    razaoSocial: '',
    idRegional: 0,
    bairro: '',
    cep: '',
    cidade: '',
    cnpj: '',
    endereco: '',
    uf: ''
  }

  const TituloForm = {
    [StatusForm.Incluindo]: 'Inclusão de Nova Unidade',
    [StatusForm.Excluindo]: 'Exclusão de Unidade Não Utilizado',
    [StatusForm.Pesquisando]: 'Unidades são utilizadas Cadastro de Boletos',
    [StatusForm.Editando]: 'Alteração de Dados de Unidades'
  }

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const [dados, setDados] = useState<UnidadeInterface>( ResetDados )

  const [erros, setErros] = useState( {} )

  const [pesquisa, setPesquisa] = useState<PesquisaInterface>( { descricao: '' } )

  const [rsPesquisa, setRsPesquisa] = useState<Array<UnidadeInterface>>( [] )

  const navigate = useNavigate()

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'descricao', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'razaoSocial', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'idRegional', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'bairro', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'cep', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'cidade', dados, erros, retorno )
    retorno = clsValidacao.eCNPJ( 'cnpj', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'endereco', dados, erros, retorno )
    retorno = clsValidacao.eUF( 'uf', dados, erros, retorno )

    setErros( erros )

    return retorno

  }

  const clsCrud: ClsCrud<UnidadeInterface> = new ClsCrud(
    navigate,
    ResetDados,
    setStatusForm,
    setDados,
    setErros,
    mensagemState,
    setMensagemState,
    setRsPesquisa,
    contexto,
    validarDados,
    {
      confirmarMutation: 'updateUnidade',
      excluirMutation: 'delUnidade',
      campoId: 'idUnidade',
      camposPesquisa: '{idUnidade descricao bairro cidade endereco}',
      pesquisaQuery: 'getUnidades',
      pesquisaPorId: 'getUnidadePorId',
      camposPesquisaPorId: '{idUnidade descricao razaoSocial idRegional bairro cep cidade cnpj endereco uf}'
    },
    {
      confirmando: 'Atualizando Unidade',
      erroCadastro: 'Erro ao Cadastrar Unidade',
      erroExclusao: 'Erro ao Excluir Unidade',
      erroPesquisa: 'Erro ao Pesquisar Unidade',
      pesquisando: 'Pesquisando Dados de Unidades...',
      sucessoCadastro: 'Unidade Cadastrada com Sucesso!',
      atualizacaoSucesso: 'Unidade Atualizada com Sucesso!',
      tituloConfirmado: 'Confirmado!',
      sucessoExclusao: 'Regional Excluída com Sucesso...',
      tituloConfirmacaoExclusao: 'Confirma?',
      tituloErroCadastro: 'Erro!',
      tituloErroExclusao: 'Erro!',
      tituloErroPesquisa: 'Erro!',
      excluindo: 'Excluindo Unidade...'
    }
  )

  const onKeyPesquisa = () => {
    clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )
  }

  const alterarCep = ( novoCep: any ): void => {
    let clsCep: ClsCep = new ClsCep()
    clsCep.alterarCep( novoCep, setMensagemState, setDados, dados, false, true )
  }


  return (
    <>
      <Container maxWidth="md" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Cadastro de Unidades
                <Typography variant="body2" gutterBottom>
                  {TituloForm[statusForm]}
                </Typography>
              </Typography>

              <IconButton onClick={() => clsCrud.btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>

              <Grid item xs={12} sm={10} >

                <InputText
                  dados={pesquisa}
                  field='descricao'
                  label='Pesquisar'
                  setState={setPesquisa}
                  iconeEnd="search"
                  onClickIconeEnd={() => clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )}
                  mapKeyPress={[{ key: 'Enter', onKey: onKeyPesquisa }]}
                />

              </Grid>

              <Grid item xs={12} sm={2} sx={{ mt: { xs: 3, sm: 4.5 }, textAlign: { xs: 'right', sm: 'center' } }}>
                <Button variant='contained' onClick={
                  () => {
                    clsCrud.btIncluir()
                  }
                }>Incluir</Button>
              </Grid>

            </Condicional>

            <Condicional condicao={statusForm !== StatusForm.Pesquisando}>

              <Grid item xs={12} sm={4} md={3}>

                <InputText
                  dados={dados}
                  field='cnpj'
                  label='CNPJ'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={18}
                  mask='cnpj'
                />

              </Grid>

              <Grid item xs={12} sm={8} md={9} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='razaoSocial'
                  label='Razão Social'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={65}
                  tipo="uppercase"
                />

              </Grid>

              <Grid item xs={12} sm={8} md={9}>

                <InputText
                  dados={dados}
                  field='descricao'
                  label='Descrição'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={30}
                  tipo="uppercase"
                />

              </Grid>

              <Grid item xs={12} sm={4} md={3} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='cep'
                  label='Cep'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  mask='cep'
                  onChange={( novoCep ) => alterarCep( novoCep )}
                />

              </Grid>

              <Grid item xs={12}>

                <InputText
                  dados={dados}
                  field='endereco'
                  label='Endereço'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={40}
                  tipo="uppercase"
                />

              </Grid>

              <Grid item xs={12} md={5}>

                <InputText
                  dados={dados}
                  field='bairro'
                  label='Bairro'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={30}
                  tipo="uppercase"
                />

              </Grid>

              <Grid item xs={12} sm={9} md={5} sx={{ pl: { md: 1 } }}>

                <InputText
                  dados={dados}
                  field='cidade'
                  label='Cidade'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={30}
                  tipo="uppercase"
                />

              </Grid>

              <Grid item xs={12} sm={3} md={2} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='uf'
                  label='UF'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  mask='uf'
                />

              </Grid>

              <Grid item xs={12}>

                <PesquisarTabela<any>
                  setState={setDados}
                  field='idRegional'
                  fieldSet='idRegional'
                  label='Regional'
                  dados={dados}
                  campoQueryPesquisaID='idRegional'
                  campoQueryPesquisa='pesquisa'
                  camposRetornoQueryPesquisa='{idRegional, descricao}'
                  campoLabelQueryPesquisa='descricao'
                  nomeQueryPesquisa='getRegionais'
                  nomeQueryPesquisaID='getRegionalPorId'
                  mensagemPesquisa='Procurando Regional...'
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  pesquisarTudoAoIniciar
                />

              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>

                <Condicional condicao={statusForm === StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmarExclusao( dados, mensagemState, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Condicional condicao={statusForm !== StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmar( dados, mensagemState, statusForm, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => clsCrud.btCancelar()}>Cancelar</Button>

              </Grid>

            </Condicional>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <DataTable dados={rsPesquisa} cabecalho={Cabecalho} acoes={[
                  { icone: 'delete', toolTip: 'Exluir', onAcionador: clsCrud.btExcluir },
                  { icone: 'create', toolTip: 'Editar', onAcionador: clsCrud.btEditar }]
                } />
              </Grid>
            </Condicional>

          </Grid>
        </Paper>

      </Container>
    </>
  )
}