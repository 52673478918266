import React, { useState, useContext, useEffect, SyntheticEvent } from 'react'
import {
  TextField,
  Autocomplete,
  Typography,
  FormControl,
  Checkbox,
  useTheme
} from '@mui/material'
import BackEndAPI from '../Services/BackEndAPI'
import { ContextoGlobal, ContextoGlobalInterface } from '../GlobalStates/ContextoGlobal'

import Condicional from '../Layout/Condicional'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

type TipoRegistroType = { [key: string]: string | number | readonly string[] | undefined | any }

export interface pesquisaInicialInterface {
  pesquisa: string
  tipo: 'Query' | 'Mutation'
  objRetorno: string
  mensagem: string
}

export interface selectMultiploPropsInterface {
  /* Label do Campo (Acima do texto) */
  label: string
  /* State para setup dos dados */
  setState: React.Dispatch<React.SetStateAction<any>>
  /* Dados Originais */
  dados: TipoRegistroType,
  /* Campo dentro do State a ser Setado */
  field: string,
  /* Campo do rsSelect a ser usado como ID - será atribuido a field na forma de array */
  fieldIdSelect: string,
  /* Campo do rsSelect a ser usado como Pesquisa / Descrição */
  fieldSelect: string,
  /* RecordSet contendo os pré dados para seleção */
  rsSelect?: Array<TipoRegistroType>,
  /* Objeto Erros */
  erros?: { [key: string]: string },
  disabled?: boolean
  onChange?: ( v: any ) => void
  pesquisaInicial?: pesquisaInicialInterface | undefined
  /* Permite Seleção Múltipla */
}

export default function SelectMultiplo ( {
  label,
  setState,
  dados,
  field,
  fieldIdSelect,
  fieldSelect,
  rsSelect,
  erros = {},
  disabled = false,
  onChange = undefined,
  pesquisaInicial
}: selectMultiploPropsInterface ) {

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const theme = useTheme()

  const abortController: AbortController = new AbortController()

  const contexto: ContextoGlobalInterface = ( useContext( ContextoGlobal ) as ContextoGlobalInterface )

  const clsApi = new BackEndAPI()

  const [rsPesquisa, setRsPesquisa] = useState<Array<TipoRegistroType>>( [] )

  const [valorPesquisa, setValorPesquisa] = useState( '' )

  useEffect( () => {

    if ( pesquisaInicial ) {

      if ( pesquisaInicial.tipo === 'Query' ) {

        clsApi.query<Array<TipoRegistroType>>( pesquisaInicial.pesquisa, pesquisaInicial.objRetorno, pesquisaInicial.mensagem, contexto ).then( rs => {
          setRsPesquisa( rs )
        } )

      } else {

        clsApi.mutation<Array<TipoRegistroType>>( pesquisaInicial.pesquisa, pesquisaInicial.objRetorno, pesquisaInicial.mensagem, contexto ).then( rs => {
          setRsPesquisa( rs )
        } )

      }

    } else if ( rsSelect ) {
      setRsPesquisa( rsSelect )
    }

    return () => {

      abortController.abort()

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  const handleChange = ( _event: SyntheticEvent<Element, Event>, value: Array<String | number> | null ) => {

    let resultado: Array<String | number> = []
    if ( value ) {
      //@ts-ignore
      resultado = value.toSorted( ( a, b ) => a - b );
    }

    if ( onChange ) {
      onChange( resultado )
    } else {
      setState( { ...dados, [field]: resultado } )
    }

  }

  const renderDescricao = ( id: String | Number ): any => {

    const indice: number = rsPesquisa.findIndex( v => v[fieldIdSelect] === id )

    if ( indice >= 0 ) {
      return rsPesquisa[indice][fieldSelect]
    } else {
      return ''
    }

  }



  return (
    <>
      <Autocomplete

        inputValue={valorPesquisa}
        onInputChange={( _event, value, reason ) => {
          if ( reason === 'input' ) {
            setValorPesquisa( value );
          }
        }}
        multiple
        disableCloseOnSelect
        disabled={disabled}
        id="AutoCompleteSelectMultiplo"
        options={rsPesquisa.map( v => v[fieldIdSelect] )}

        getOptionLabel={( opcao: any ) => {
          if ( opcao && typeof opcao === 'object' && opcao[fieldSelect] ) {
            return opcao[fieldSelect]
          } else {

            const retorno = rsPesquisa.find( ( v ) => v[fieldIdSelect] === opcao )

            if ( retorno && retorno[fieldSelect] ) {
              return retorno[fieldSelect]
            } else {
              return ''
            }

          }
        }
        }
        onChange={handleChange}

        renderInput={( params ) =>
          <FormControl sx={{ width: '100%' }}>
            <Typography
              variant='body2'
              textAlign='left'
              sx={{ mt: theme.inputs.marginTop }}
            >
              {label}
            </Typography>
            <TextField 
            {...params}
              size="small"
            /*
               onKeyDown={
                ( ev ) => {

                  if ( ev.key === 'Enter' ) {
                    onClickSearch()
                  }

                  return null

                }
                
              } 
              */
            />
            <Condicional condicao={typeof erros[field] !== 'undefined'}>
              <Typography variant='caption' textAlign='left' color='warning.main' >{erros[field]}</Typography>
            </Condicional>
          </FormControl>
        }


        renderOption={( props, option, { selected } ) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {renderDescricao( option )}
          </li>
        )}
      />
    </>
  )

}