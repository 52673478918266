import { gql } from "@apollo/client"
import { ContextoGlobalInterface } from "../../GlobalStates/ContextoGlobal"
import { LayoutStateInterface } from "../../GlobalStates/LayoutState"

export default class ClsPesquisaUnidadeAtual {

  public setarDescricaoUnidade (
    idUnidade: number,
    contextoGlobal: ContextoGlobalInterface,
    layoutState: LayoutStateInterface,
    setLayoutState: React.Dispatch<React.SetStateAction<LayoutStateInterface>>
  ) {

    if ( idUnidade !== 0 ) {

      const query: string = `
        getUnidadePorId (idUnidade: ${idUnidade}) {
          descricao
        }
      `

      contextoGlobal.apolloClient
        .query( {
          query: gql( `query { ${query} }` )
        } ).then( rs => {
          setLayoutState( { ...layoutState, descricaoUnidadeAtual: rs.data.getUnidadePorId.descricao } )
        } )

    } else {
      setLayoutState( { ...layoutState, descricaoUnidadeAtual: 'Escolha a Unidade' } )
    }

  }

}