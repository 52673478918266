import React, { useState, useEffect, useContext, useRef } from 'react'
import { Button, Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import ClsFormatacao from '../../Utils/ClsFormatacao'
import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable'
import BackEndAPI from '../../Services/BackEndAPI'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import { RelatorioSaldoUnidadeInterface } from '../../ImportBackend/Interfaces/RelatorioInterfaces'
import { MensagemTipo } from '../../GlobalStates/MensagemState'
import Condicional from '../../Layout/Condicional'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import ClsExportDataTableCSV from '../../Utils/ClsExportDataTableCSV'

import { useReactToPrint } from 'react-to-print'
import CabecalhoImpressao from '../../Layout/CabecalhoImpressao'
import { SISTEMA_PERMISSOES } from '../../ImportBackend/types/AcessosDataTypes'
import ClsAcesso from '../../Utils/ClsAcesso'

export default function Saldos () {

  const componentRef = useRef( null )

  const handlePrint = useReactToPrint( {
    content: () => componentRef.current,
  } )

  const handleDownload = () => {

    ( new ClsExportDataTableCSV() ).exportCSV( 'Saldos', rsSaldoUnidades, CabecalhoSaldoUnidades )

  }

  const clsFormatacao: ClsFormatacao = new ClsFormatacao()

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const [rsSaldoUnidades, setRsSaldoUnidades] = useState<Array<RelatorioSaldoUnidadeInterface>>( [] )

  const [rsSaldoCora, setRsSaldoCora] = useState<number>( 0 )
  const [rsSaldoGeral, setRsSaldoGeral] = useState<number>( 0 )

  const [rsSaldoUnidadesConferencia, setRsSaldoUnidadesConferencia] = useState<number>( 0 )

  const CabecalhoSaldoUnidades: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'descricao',
      cabecalho: 'Unidade',
      alinhamento: 'left'
    },
    {
      campo: 'saldoUnidade',
      cabecalho: 'Saldo',
      alinhamento: 'right',
      format: ( v: number ) => clsFormatacao.currency( v )
    }]

  const abortController: AbortController = new AbortController()

  const navigate = useNavigate()

  const btFechar = () => {
    navigate( '/' )
  }

  const consultarCora = () => {
    const query = `
      SaldoContaCora
    `
    const clsApi = new BackEndAPI()

    clsApi.query<number>( query, 'SaldoContaCora', 'Pesquisando Saldo Conta Cora...', contexto, abortController ).then( rsSaldo => {

      setRsSaldoCora( rsSaldo )

    } ).catch( ( e ) => {
      console.log( e.message )

      setMensagemState( {
        ...mensagemState,
        titulo: 'Erro! Consulte Suporte!',
        exibir: true,
        mensagem: 'Erro ao Consultar Saldo Cora!',
        tipo: MensagemTipo.Error,
        exibirBotao: true
      } )

    } )
  }

  const consultarSaldoGeral = () => {
    const query = `
      saldoGeral
    `
    const clsApi = new BackEndAPI()

    clsApi.query<number>( query, 'saldoGeral', 'Pesquisando Saldo Geral...', contexto, abortController ).then( rsSaldo => {

      setRsSaldoGeral( rsSaldo )

    } ).catch( ( e ) => {
      console.log( e.message )

      setMensagemState( {
        ...mensagemState,
        titulo: 'Erro! Consulte Suporte!',
        exibir: true,
        mensagem: 'Erro ao Consultar Saldo Geral!',
        tipo: MensagemTipo.Error,
        exibirBotao: true
      } )

    } )
  }

  const consultarUnidades = () => {
    const query = `
      saldosDasUnidades {
        idUnidade
        descricao
        saldoUnidade
      }
    `
    const clsApi = new BackEndAPI()

    clsApi.query<Array<RelatorioSaldoUnidadeInterface>>( query, 'saldosDasUnidades', 'Pesquisando Saldo das Unidades...', contexto, abortController ).then( rsSaldo => {

      let saldoGeralUndiadesConferencia: number = 0

      rsSaldo.forEach( v => {
        saldoGeralUndiadesConferencia += v.saldoUnidade
      } )

      setRsSaldoUnidadesConferencia( saldoGeralUndiadesConferencia )

      setRsSaldoUnidades( rsSaldo )

    } ).catch( ( e ) => {
      console.log( e.message )

      setMensagemState( {
        ...mensagemState,
        titulo: 'Erro! Consulte Suporte!',
        exibir: true,
        mensagem: 'Erro ao Consultar Saldo das Unidades!',
        tipo: MensagemTipo.Error,
        exibirBotao: true
      } )

    } )
  }

  const clsAcesso: ClsAcesso = new ClsAcesso()

  const permitirAcessoSaldos = clsAcesso.chkAcesso(
    contexto.loginState.permissoes,
    SISTEMA_PERMISSOES.RELATORIOS.MODULO,
    SISTEMA_PERMISSOES.RELATORIOS.PERMISSOES.CONSULTARSALDOGERAL
  )

  useEffect( () => {

    consultarUnidades()

    if ( permitirAcessoSaldos ) {

      consultarCora()

      consultarSaldoGeral()

    }

    return () => {

      abortController.abort()

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  return (
    <>
      <Container maxWidth="sm" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>

          <CabecalhoImpressao />

          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography component="h5" variant="h5" align="left">
                Saldos das Unidades
                <Typography variant="body2" gutterBottom>
                  Saldos Atualizados das Unidades para Conferência
                </Typography>
              </Typography>

              <div>
                <IconButton onClick={() => btFechar()}>
                  <CloseIcon />
                </IconButton>
              </div>

            </Grid>

            <Grid item xs={12} sx={{ mt: 3 }}>
              <DataTable
                dados={rsSaldoUnidades}
                cabecalho={CabecalhoSaldoUnidades}
              />
            </Grid>

            <Condicional condicao={permitirAcessoSaldos}>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                <Typography component="h5" variant="h5" align="left">
                  Saldo Geral
                  <Typography variant="body2" gutterBottom>
                    {clsFormatacao.currency( rsSaldoGeral )}
                  </Typography>
                </Typography>
              </Grid>

              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                <Typography component="h5" variant="h5" align="left">
                  Saldo Cora
                  <Typography variant="body2" gutterBottom>
                    {clsFormatacao.currency( rsSaldoCora )}
                  </Typography>
                </Typography>
              </Grid>

              <Condicional condicao={rsSaldoUnidadesConferencia !== rsSaldoCora}>
                <Typography component="h5" variant="h5" align="left">
                  Diferença Entre Saldo Cora e Saldo Geral!
                </Typography>
              </Condicional>

              <Condicional condicao={rsSaldoUnidadesConferencia !== rsSaldoGeral}>
                <Typography component="h5" variant="h5" align="left">
                  Saldo das Unidades Diferente do Saldo Geral!!! Refazer Cálculo de Saldos!
                </Typography>
              </Condicional>
            </Condicional>

            <Condicional condicao={rsSaldoUnidades.length > 0}>
              <Grid className="noprint" item xs={12} alignSelf='center' sx={{ mt: { xs: 0, sm: 2 }, textAlign: 'right' }}>
                <Button variant='contained' onClick={() => handlePrint()}>Imprimir</Button>
                <Button variant='contained' onClick={() => handleDownload()} sx={{ ml: 1 }}>Download</Button>
              </Grid>
            </Condicional>

          </Grid>
        </Paper>
      </Container>

    </>
  )

}