import React, { useContext, useState, useRef } from 'react'
import { Button, Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import ClsFormatacao from '../../Utils/ClsFormatacao'
import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable'
import BackEndAPI from '../../Services/BackEndAPI'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import { MensagemTipo } from '../../GlobalStates/MensagemState'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import { RespostaPadraoInterface } from '../../ImportBackend/Interfaces/PadraoInterfaces'
import PesquisarTabela from '../../DevComponents/PesquisarTabela'
import InputText from '../../DevComponents/InputText'
import ClsValidacao from '../../Utils/ClsValidacao'

import { useReactToPrint } from 'react-to-print'
import Condicional from '../../Layout/Condicional'
import CabecalhoImpressao from '../../Layout/CabecalhoImpressao'
import SelectMultiplo from '../../DevComponents/SelectMultiplo'
import ClsExportDataTableCSV from '../../Utils/ClsExportDataTableCSV'

interface PesquisaInterface {
  dataInicial: string
  dataFinal: string
  unidades: Array<number>
  idRegional: number
}

export default function ContratosEmitidos () {

  const componentRef = useRef( null )

  const handlePrint = useReactToPrint( {
    content: () => componentRef.current,
  } )

  const handleDownload = () => {

    ( new ClsExportDataTableCSV() ).exportCSV( 'ContratosEmitidos', rsRelatorio, CabecalhoRelatorio )

  }

  const clsFormatacao: ClsFormatacao = new ClsFormatacao()

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const [rsRelatorio, setRsRelatorio] = useState<Array<any>>( [] )

  const CabecalhoRelatorio: Array<DataTableCabecalhoInterface> = [

    /*
    SELECT  ct.idContrato
           ,ct.idCliente
           ,cl.nome      AS nome
           ,ct.idUnidade
           ,un.descricao AS descricaoUnidade
           ,ct.idProduto
           ,pr.descricao AS descricaoProduto
           ,re.descricao AS descricaoRegional
           ,ct.valorTotal
           ,ct.primeiroVencimento
           ,ct.parcelas
           ,ct.vrDesconto
           ,ct.perJuros
           ,ct.STATUS
           ,ct.cpfResponsavelPreenchimento
           ,ct.nomeResponsavelPreenchimento
           ,ct.parcelasCora->"$.document_url"
    
    
    
    */
    {
      campo: 'idContrato',
      cabecalho: 'Contrato',
      alinhamento: 'left',
      format: ( v: number ) => '000000'.concat( v.toString() ).slice( -6 )
    },
    {
      campo: 'nome',
      cabecalho: 'Cliente',
      alinhamento: 'left'
    },
    {
      campo: 'descricaoUnidade',
      cabecalho: 'Unidade',
      alinhamento: 'left'
    },
    {
      campo: 'createdAt',
      cabecalho: 'Emissão',
      alinhamento: 'left',
      format: ( v: string ) => clsFormatacao.dataISOtoUser( v )
    },
    {
      campo: 'descricaoProduto',
      cabecalho: 'Produto',
      alinhamento: 'left'
    },
    {
      campo: 'valorTotal',
      cabecalho: '$ Total',
      alinhamento: 'right',
      format: ( v: string ) => clsFormatacao.currency( parseFloat( v ) )
    }
  ]

  const abortController: AbortController = new AbortController()

  const navigate = useNavigate()

  const btFechar = () => {
    navigate( '/' )
  }

  const [pesquisa, setPesquisa] = useState<PesquisaInterface>( {
    dataInicial: '',
    dataFinal: '',
    unidades: [],
    idRegional: 0
  } )

  const validarPesquisa = (): boolean => {
    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.eData( 'dataInicial', pesquisa, erros, retorno, true )
    retorno = clsValidacao.eData( 'dataFinal', pesquisa, erros, retorno, true )

    if ( retorno
      && pesquisa.dataInicial.length > 0
      && pesquisa.dataFinal.length > 0
      && pesquisa.dataInicial > pesquisa.dataFinal
    ) {
      erros.dataInicial = 'Período Inválido!'
      erros.dataFinal = 'Período Inválido!'
      retorno = false
    }

    setErrosPesquisa( erros )

    if ( retorno ) {
      consultarRelatorio()
    }

    return retorno
  }

  const [errosPesquisa, setErrosPesquisa] = useState( {} )

  const consultarRelatorio = () => {
    const query = `
      relContratos (
        dataInicial: "${pesquisa.dataInicial}",
        dataFinal: "${pesquisa.dataFinal}",
        unidades: ${JSON.stringify( pesquisa.unidades )},
        idRegional: ${pesquisa.idRegional},
      ) {
        ok
        mensagem
      }
    `
    const clsApi = new BackEndAPI()

    clsApi.query<RespostaPadraoInterface>( query, 'relContratos', 'Gerando Relatório de Contratos...', contexto, abortController ).then( rsOK => {

      if ( rsOK.ok ) {
        setRsRelatorio( JSON.parse( rsOK.mensagem ) )
      } else {
        setRsRelatorio( [] )
      }

    } ).catch( ( e ) => {
      console.log( e.message )

      setMensagemState( {
        ...mensagemState,
        titulo: 'Erro! Consulte Suporte!',
        exibir: true,
        mensagem: 'Erro ao Gerar Relatório de Produtividade!',
        tipo: MensagemTipo.Error,
        exibirBotao: true
      } )

    } )
  }

  const btExibirContrato = ( rs: any ) => {
    // console.log( rs.idContrato )
    navigate( '/Clientes', { replace: true, state: { pesquisaInicial: '000000'.concat( rs.idContrato.toString() ).slice( -6 ) } } )
  }

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 5 }} >

        <Paper variant="outlined" sx={{ padding: 2 }} className='content' ref={componentRef} >

          <CabecalhoImpressao />

          <Grid container sx={{ display: 'flex', alignItems: 'center' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Contratos Emitidos
                <Typography variant="body2" gutterBottom>
                  Relação de Contratos Emitidos
                </Typography>
              </Typography>

              <IconButton onClick={() => btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Grid item xs={12} sx={{ mb: 3 }}>

              <Grid container sx={{ display: 'flex', alignItems: 'center' }}>

                <Grid item xs={12} >

                  <PesquisarTabela<any>
                    setState={setPesquisa}
                    field='idRegional'
                    fieldSet='idRegional'
                    label='Regional'
                    dados={pesquisa}
                    campoQueryPesquisaID='idRegional'
                    campoQueryPesquisa='pesquisa'
                    camposRetornoQueryPesquisa='{idRegional, descricao}'
                    campoLabelQueryPesquisa='descricao'
                    nomeQueryPesquisa='getRegionais'
                    nomeQueryPesquisaID='getRegionalPorId'
                    mensagemPesquisa='Procurando Regional...'
                    erros={errosPesquisa}
                    pesquisarTudoAoIniciar={true}
                  />

                </Grid>

                <Grid item xs={12} >

                  <SelectMultiplo
                    dados={pesquisa}
                    field='unidades'
                    fieldIdSelect='idUnidade'
                    fieldSelect='descricao'
                    label='Unidades'
                    setState={setPesquisa}
                    pesquisaInicial={{
                      mensagem: 'Pesquisando Unidades',
                      tipo: 'Query',
                      pesquisa: 'getUsuarioUnidadesPermitidasPorAcesso {idUnidade, descricao}',
                      objRetorno: 'getUsuarioUnidadesPermitidasPorAcesso'
                    }
                    }
                  />

                </Grid>

                <Grid item xs={12} sm={6}>

                  <InputText
                    dados={pesquisa}
                    field='dataInicial'
                    label='Início'
                    setState={setPesquisa}
                    erros={errosPesquisa}
                    tipo='date'
                    mask='00/00/0000'
                    textAlign="right"
                  />

                </Grid>

                <Grid item xs={12} sm={6} sx={{ pl: { sm: 1 } }}>

                  <InputText
                    dados={pesquisa}
                    field='dataFinal'
                    label='Término'
                    setState={setPesquisa}
                    erros={errosPesquisa}
                    tipo='date'
                    mask='00/00/0000'
                    textAlign="right"
                  />

                </Grid>

                <Grid className="noprint" item xs={6} sx={{ textAlign: 'left', mt: 3 }}>
                  <Button variant='contained' onClick={() => validarPesquisa()}>Procurar</Button>
                </Grid>

              </Grid>

            </Grid>

            <Grid item xs={12} sx={{ mt: 3 }}>
              <DataTable
                dados={rsRelatorio}
                cabecalho={CabecalhoRelatorio}
                acoes={[
                  {
                    icone: 'visibility',
                    toolTip: 'Exibir Contrato',
                    onAcionador: btExibirContrato
                  }
                ]}
              />
            </Grid>

          </Grid>

          <Condicional condicao={rsRelatorio.length > 0}>
            <Grid className="noprint" item xs={12} sm={2} alignSelf='center' sx={{ mt: { xs: 0, sm: 2 }, textAlign: 'right' }}>
              <Button variant='contained' onClick={() => handlePrint()}>Imprimir</Button>
              <Button variant='contained' onClick={() => handleDownload()} sx={{ ml: 1 }}>Download</Button>
            </Grid>
          </Condicional>

        </Paper>

      </Container>

    </>
  )

}