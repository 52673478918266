import React, { useContext, useState, useRef } from 'react'
import { Button, Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import ClsFormatacao from '../../Utils/ClsFormatacao'
import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable'
import BackEndAPI from '../../Services/BackEndAPI'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import { MensagemTipo } from '../../GlobalStates/MensagemState'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import { RespostaPadraoInterface } from '../../ImportBackend/Interfaces/PadraoInterfaces'
import InputText from '../../DevComponents/InputText'
import ClsValidacao from '../../Utils/ClsValidacao'

import { useReactToPrint } from 'react-to-print'
import Condicional from '../../Layout/Condicional'
import CabecalhoImpressao from '../../Layout/CabecalhoImpressao'
import ComboBox from '../../DevComponents/ComboBox'
import ClsExportDataTableCSV from '../../Utils/ClsExportDataTableCSV'
import ExibirJSONDev from '../../DevComponents/ExibirJSONDev'
import { EMDESENVOLVIMENTO } from '../../ImportBackend/Config/emDesenvolvimento'
import { rsConsultaParcelaInterface } from '../../ImportBackend/Interfaces/CoraInterfaces'

interface PesquisaInterface {
  start: string
  end: string
  state: string
  search: string
  page: number
  perPage: number
}

export default function CoraOnLine () {

  const componentRef = useRef( null )

  const handlePrint = useReactToPrint( {
    content: () => componentRef.current,
  } )

  const handleDownload = () => {

    ( new ClsExportDataTableCSV() ).exportCSV( 'Cora', rsRelatorio, CabecalhoRelatorio )

  }

  const clsFormatacao: ClsFormatacao = new ClsFormatacao()

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const [rsRelatorio, setRsRelatorio] = useState<Array<rsConsultaParcelaInterface>>( [] )

  const CabecalhoRelatorio: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'id',
      cabecalho: 'Identificador Único',
      alinhamento: 'left'
    },
    {
      campo: 'customer_name',
      cabecalho: 'Cliente',
      alinhamento: 'left'
    },
    {
      campo: 'customer_document',
      cabecalho: 'CPF',
      alinhamento: 'left',
      format: ( v: string ) => v.replace( /(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4" )
    },
    {
      campo: 'status',
      cabecalho: 'Status',
      alinhamento: 'left'
    },
    {
      campo: 'total_amount',
      cabecalho: '$ Total',
      alinhamento: 'right',
      format: ( v: string ) => clsFormatacao.currency( parseFloat( v ) / 100 )
    },
    {
      campo: 'due_date',
      cabecalho: 'Vencimento',
      alinhamento: 'right',
      format: ( v: any ) => clsFormatacao.dataISOtoUser( v )
    },
    {
      campo: 'total_paid',
      cabecalho: '$ Pago',
      alinhamento: 'right',
      format: ( v: string ) => v ? clsFormatacao.currency( parseFloat( v ) / 100 ) : ''
    },
    /*
{
campo: 'payment_options',
cabecalho: 'Link Download',
alinhamento: 'left',
format: ( v: any ) => v.bank_slip.url
}*/
  ]

  const abortController: AbortController = new AbortController()

  const navigate = useNavigate()

  const btFechar = () => {
    navigate( '/' )
  }

  const [pesquisa, setPesquisa] = useState<PesquisaInterface>( {
    start: '',
    end: '',
    state: '',
    search: '',
    page: 1,
    perPage: 100
  } )

  const validarPesquisa = (): boolean => {
    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.eData( 'start', pesquisa, erros, retorno, true )
    retorno = clsValidacao.eData( 'end', pesquisa, erros, retorno, true )
    retorno = clsValidacao.eCPF( 'search', pesquisa, erros, retorno, true )
    retorno = clsValidacao.naoVazio( 'page', pesquisa, erros, retorno )
    retorno = clsValidacao.naoVazio( 'perPage', pesquisa, erros, retorno )

    if ( retorno
      && pesquisa.start.length > 0
      && pesquisa.end.length > 0
      && pesquisa.start > pesquisa.end
    ) {
      erros.start = 'Período Inválido!'
      erros.end = 'Período Inválido!'
      retorno = false
    }

    setErrosPesquisa( erros )

    if ( retorno ) {
      btConsultarRelatorio()
    }

    return retorno
  }

  const [errosPesquisa, setErrosPesquisa] = useState( {} )

  const btConsultarRelatorio = () => {
    const query = `
      relCoraOnLine (
        start: "${pesquisa.start}",
        end: "${pesquisa.end}",
        state: "${pesquisa.state}",
        search: "${pesquisa.search}",
        page: ${pesquisa.page},
        perPage: ${pesquisa.perPage}
      ) {
        ok
        mensagem
      }
    `
    const clsApi = new BackEndAPI()

    clsApi.query<RespostaPadraoInterface>( query, 'relCoraOnLine', 'Consultando Cora On Line...', contexto, abortController ).then( rsOK => {

      if ( rsOK.ok ) {
        setRsRelatorio( JSON.parse( rsOK.mensagem ) )
      } else {
        setRsRelatorio( [] )
      }

    } ).catch( ( e ) => {

      setMensagemState( {
        ...mensagemState,
        titulo: 'Erro! Consulte Suporte!',
        exibir: true,
        mensagem: 'Erro ao Consultar Cora!',
        tipo: MensagemTipo.Error,
        exibirBotao: true
      } )

    } )
  }

  const btDownloadParcela = ( rs: rsConsultaParcelaInterface ) => {

    const clsApi: BackEndAPI = new BackEndAPI()

    const query: string = `
        downloadParcelaPorIdParcelaCora(idParcelaCora: "${rs.id}" ) {
          ok
          mensagem
        }
    `

    clsApi.query<RespostaPadraoInterface>( query, 'downloadParcelaPorIdParcelaCora', 'Downloading Parcela...', contexto ).then( rsOK => {

      if ( rsOK.ok && rsOK.mensagem.length > 0 ) {
        let link = document.createElement( 'a' )
        // link.href = 'data:application/octet-stream;base64,' + rsDownload;
        link.href = rsOK.mensagem
        link.click()
      } else {
        contexto.setMensagemState( {
          ...contexto.mensagemState,
          exibirBotao: true,
          mensagem: 'Faça o download individual de cada parcela.',
          titulo: 'Erro no Download',
          tipo: MensagemTipo.Error,
          exibir: true
        } )
      }
    } )
  }

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 5 }} >

        <Paper variant="outlined" sx={{ padding: 2 }} className='content' ref={componentRef} >

          <CabecalhoImpressao />

          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Consultas Cora
                <Typography variant="body2" gutterBottom>
                  Consulta Base de Dados da Cora em Tempo Real
                </Typography>
              </Typography>

              <IconButton onClick={() => btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Grid item xs={12} sx={{ mb: 3 }}>

              <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

                <Grid item xs={12} sm={6} md={3}>

                  <InputText
                    dados={pesquisa}
                    field='search'
                    label='CPF'
                    setState={setPesquisa}
                    erros={errosPesquisa}
                    maxLength={14}
                    mask='cpf'
                  />

                </Grid>

                <Grid item xs={12} sm={6} md={3} sx={{ pl: { sm: 1 } }} >

                  <ComboBox
                    dados={pesquisa}
                    label='Status'
                    setState={setPesquisa}
                    opcoes={[
                      { id: 'OPEN', descricao: 'Aberto' },
                      { id: 'PAID', descricao: 'Pagas' },
                      { id: 'LATE', descricao: 'Em Atraso' }
                    ]}
                    campoDescricao='descricao'
                    campoID='id'
                    field='state'
                    valorPadraoCampoEmBranco={''}
                    erros={errosPesquisa}
                  />

                </Grid>

                <Grid item xs={6} md={2} sx={{ pl: { md: 1 } }} >

                  <InputText
                    dados={pesquisa}
                    field='start'
                    label='Início'
                    setState={setPesquisa}
                    erros={errosPesquisa}
                    tipo='date'
                    mask='00/00/0000'
                    textAlign="right"
                  />

                </Grid>

                <Grid item xs={6} md={2} sx={{ pl: { xs: 1 } }} >

                  <InputText
                    dados={pesquisa}
                    field='end'
                    label='Término'
                    setState={setPesquisa}
                    erros={errosPesquisa}
                    tipo='date'
                    mask='00/00/0000'
                    textAlign="right"
                  />

                </Grid>

                <Grid item xs={6} md={1} sx={{ pl: { md: 1 } }} >

                  <InputText
                    dados={pesquisa}
                    field='page'
                    label='Página'
                    setState={setPesquisa}
                    erros={errosPesquisa}
                    maxLength={3}
                    tipo='number'
                    mask='00'
                  />

                </Grid>

                <Grid item xs={6} md={1} sx={{ pl: { xs: 1 } }} >

                  <InputText
                    dados={pesquisa}
                    field='perPage'
                    label='Itens Pág.'
                    setState={setPesquisa}
                    erros={errosPesquisa}
                    maxLength={3}
                    tipo='number'
                    mask='000'
                  />

                </Grid>

                <Grid className="noprint" item xs={6} sx={{ textAlign: 'left', mt: 3 }}>
                  <Button variant='contained' onClick={() => validarPesquisa()}>Procurar</Button>
                </Grid>

              </Grid>

            </Grid>

            <Grid item xs={12} sx={{ mt: 3 }}>
              <DataTable
                dados={rsRelatorio}
                cabecalho={CabecalhoRelatorio}
                acoes={
                  [{
                    icone: 'payments',
                    toolTip: 'Download Parcelas',
                    onAcionador: btDownloadParcela
                  }]}
              />
            </Grid>

          </Grid>

          <Condicional condicao={rsRelatorio.length > 0}>
            <Grid className="noprint" item xs={12} sm={2} alignSelf='center' sx={{ mt: { xs: 0, sm: 2 }, textAlign: 'right' }}>
              <Button variant='contained' onClick={() => handlePrint()}>Imprimir</Button>
              <Button variant='contained' onClick={() => handleDownload()} sx={{ ml: 1 }}>Download</Button>
            </Grid>
          </Condicional>

        </Paper>

        <ExibirJSONDev exibir={EMDESENVOLVIMENTO} oque={['rsRelatorio', rsRelatorio]} />

      </Container>

    </>
  )

}