import React, { useContext, useEffect, useState } from 'react'
import { RespostaPadraoInterface } from '../ImportBackend/Interfaces/PadraoInterfaces'
import BackEndAPI from '../Services/BackEndAPI'
import { ContextoGlobal, ContextoGlobalInterface } from '../GlobalStates/ContextoGlobal'
import Condicional from './Condicional'
import { Alert, Grid } from '@mui/material'

export default function StatusCora () {

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const [rsMensagem, setRsMensagem] = useState<RespostaPadraoInterface>( { ok: true, mensagem: 'Verificando Status do Cora...' } )

  const statusCora = () => {
    const query: string = `
      StatusCora {
        ok
        mensagem
      }
    `

    const clsApi = new BackEndAPI()

    clsApi.query<RespostaPadraoInterface>( query, 'StatusCora', 'Checando Status Cora...', contexto ).then( rs => {
      // console.log(rs)
      setRsMensagem( rs )
    } )

  }

  useEffect( () => {

    statusCora()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  return (
    <>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
      >
        <Condicional condicao={!rsMensagem.ok}>
          <Alert severity="error">Sistema Não Operacional. Consulte Suporte. Não é possível emitir novos contratos.</Alert>
        </Condicional>
      </Grid>
    </>
  )

}