import { Box, Grid, Paper, Typography, IconButton } from '@mui/material'
import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import ComboBox from '../../DevComponents/ComboBox'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import { MensagemTipo } from '../../GlobalStates/MensagemState'
import { RespostaPadraoInterface } from '../../ImportBackend/Interfaces/PadraoInterfaces'
import { UnidadeInterface } from '../../ImportBackend/Interfaces/UnidadeInterfaces'
import BackEndAPI from '../../Services/BackEndAPI'
import CloseIcon from '@mui/icons-material/Close'

export default function SetUnidade () {

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto
  const { loginState, setLoginState } = contexto

  const [disable, setDisable] = useState( false )

  const [rsUnidades, setRsUnidades] = useState<Array<UnidadeInterface>>( [] )

  const clsApi: BackEndAPI = new BackEndAPI()

  const navigate = useNavigate()

  const btFechar = () => {
    navigate( '/' )
  }

  const setUnidade = ( rsUnidade: UnidadeInterface ) => {

    const idUnidade: number = rsUnidade.idUnidade && rsUnidade.idUnidade !== 0 ? rsUnidade.idUnidade : 0

    if ( idUnidade !== 0 ) {

      setDisable( true )

      const mutation = `
        setUsuarioUnidade(idUnidade: ${idUnidade}) {
          ok
          mensagem
        }
      `

      clsApi.mutation<RespostaPadraoInterface>( mutation, 'setUsuarioUnidade', 'Alterando Unidade...', contexto ).then( rs => {

        if ( rs.ok ) {
          setLoginState( { ...loginState, idUnidadeAtual: idUnidade } )
          setDados( { idUnidade: idUnidade } )
          setDisable( false )
        } else {
          setDisable( false )
          setLoginState( { ...loginState, idUnidadeAtual: 0 } )
        }

      } ).catch( () => {

        setMensagemState( {
          ...mensagemState,
          titulo: 'Erro! Consulte Suporte!',
          exibir: true,
          mensagem: 'Erro ao Alterar Unidade do Usuário!',
          tipo: MensagemTipo.Error,
          exibirBotao: true
        } )

        setDisable( false )

      } )

    }

  }

  const abortController: AbortController = new AbortController()

  const pesquisarUsuarioUnidades = () => {
    const query = `
      getUsuarioUnidadesPermitidasPorAcesso {
        idUnidade
        descricao
      }
    `

    clsApi.query<Array<UnidadeInterface>>( query, 'getUsuarioUnidadesPermitidasPorAcesso', 'Recebendo Unidades...', contexto, abortController ).then( rsUnidades => {

      setRsUnidades( rsUnidades )

      if ( rsUnidades.length === 1 ) {
        setUnidade( rsUnidades[0] )
      }

    } ).catch( () => {

      setMensagemState( {
        ...mensagemState,
        titulo: 'Erro! Consulte Suporte!',
        exibir: true,
        mensagem: 'Erro ao Consultar Unidades de Usuários!',
        tipo: MensagemTipo.Error,
        exibirBotao: true
      } )

    } )
  }

  useEffect( () => {
    pesquisarUsuarioUnidades()

    return () => {

      abortController.abort()

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  const [dados, setDados] = useState( { idUnidade: 0 } )

  return (
    <>

      <Grid
        container
        justifyContent='center'
        alignItems='center'
        height='50vh'
      >
        <Grid item xs={10} sm={8} md={6} lg={4}>
          <Paper>

            <Box
              sx={{ backgroundColor: 'white', padding: 2, mx: 5 }}
            >
              <Grid>
                <Grid item textAlign='right'>
                  <IconButton onClick={() => btFechar()}>
                    <CloseIcon />
                  </IconButton>
                </Grid>


                <Grid item textAlign='center'>
                  <Typography variant="h5" fontFamily='sans-serif' fontWeight='bolder' color="primary.main">
                    Selecione uma Unidade
                  </Typography>
                </Grid>

                <Grid item xs={12}>

                  <ComboBox
                    campoDescricao='descricao'
                    campoID='idUnidade'
                    opcoes={rsUnidades}
                    dados={dados}
                    field='idUnidade'
                    label='Acessar Dados de:'
                    setState={setDados}
                    onChange={( v: UnidadeInterface ) => setUnidade( v )}
                    disabled={disable}
                  />

                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>

    </>
  )

}