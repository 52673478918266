import React, { useContext } from 'react'
import { Container, Grid, Paper, Typography } from '@mui/material'
import { ContratoParcelaInterface } from '../../ImportBackend/Interfaces/ContratoInterfaces'
import { StatusParcelaTypes } from '../../ImportBackend/types/ConstantesDataTypes'
import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable'
import ClsFormatacao from '../../Utils/ClsFormatacao'
import BackEndAPI from '../../Services/BackEndAPI'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import { RespostaPadraoInterface } from '../../ImportBackend/Interfaces/PadraoInterfaces'
import { MensagemTipo } from '../../GlobalStates/MensagemState'

interface ProposInterface {
  parcelas: Array<ContratoParcelaInterface> | undefined
}

export default function ClientesContratosParcelasView ( { parcelas }: ProposInterface ) {

  const clsFormatacao: ClsFormatacao = new ClsFormatacao()

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'vencimento',
      cabecalho: 'Vencimento',
      alinhamento: 'left',
      format: ( v: string ) => clsFormatacao.dataISOtoUser( v )
    },
    {
      campo: 'status',
      cabecalho: 'Status',
      alinhamento: 'left',
      format: ( v: string ) => StatusParcelaTypes.find( ( e ) => e.idStatus === v )?.descricao
    },
    {
      campo: 'valor',
      cabecalho: 'Valor',
      alinhamento: 'right',
      format: ( v: number ) => clsFormatacao.currency( v )
    },
    {
      campo: 'vrRecebido',
      cabecalho: 'Vr. Recebido',
      alinhamento: 'right',
      format: ( v: number ) => clsFormatacao.currency( v )
    },
    {
      campo: 'vrDescontoRecebido',
      cabecalho: 'Vr. Desconto',
      alinhamento: 'right',
      format: ( v: number ) => clsFormatacao.currency( v )
    },
    {
      campo: 'vrJurosRecebido',
      cabecalho: 'Vr. Juros',
      alinhamento: 'right',
      format: ( v: number ) => clsFormatacao.currency( v )
    }
  ]

  const btDownloadParcela = ( rs: ContratoParcelaInterface ) => {

    const clsApi: BackEndAPI = new BackEndAPI()

    const query: string = `
        downloadParcela(idContratoParcela: ${rs.idContratoParcela} ) {
          ok
          mensagem
        }
    `

    clsApi.query<RespostaPadraoInterface>( query, 'downloadParcela', 'Downloading Parcela...', contexto ).then( rsOK => {

      if ( rsOK.ok && rsOK.mensagem.length > 0 ) {
        let link = document.createElement( 'a' )
        // link.href = 'data:application/octet-stream;base64,' + rsDownload;
        link.href = rsOK.mensagem
        link.click()
      } else {
        contexto.setMensagemState( {
          ...contexto.mensagemState,
          exibirBotao: true,
          mensagem: 'Faça o download individual de cada parcela.',
          titulo: 'Erro no Download',
          tipo: MensagemTipo.Error,
          exibir: true
        } )
      }
    } )
  }

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography component="h5" variant="h5" align="left">
                Parcelas
              </Typography>
            </Grid>

            <Grid item xs={12} sx={{ mt: 3 }}>
              <DataTable
                dados={parcelas as ContratoParcelaInterface[]}
                cabecalho={Cabecalho}
                acoes={[
                  { icone: 'payments', toolTip: 'Download', onAcionador: btDownloadParcela }
                ]}
              />

            </Grid>

          </Grid>
        </Paper>
      </Container>
    </>
  )

}