import React, { useContext, useState, useRef } from 'react'
import { Button, Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import ClsFormatacao from '../../Utils/ClsFormatacao'
import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable'
import BackEndAPI from '../../Services/BackEndAPI'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import { MensagemTipo } from '../../GlobalStates/MensagemState'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import { RespostaPadraoInterface } from '../../ImportBackend/Interfaces/PadraoInterfaces'
import PesquisarTabela from '../../DevComponents/PesquisarTabela'
import InputText from '../../DevComponents/InputText'
import ClsValidacao from '../../Utils/ClsValidacao'
import { useReactToPrint } from 'react-to-print';
import Condicional from '../../Layout/Condicional'
import CabecalhoImpressao from '../../Layout/CabecalhoImpressao'
import SelectMultiplo from '../../DevComponents/SelectMultiplo'
import ClsExportDataTableCSV from '../../Utils/ClsExportDataTableCSV'
import ComboBox from '../../DevComponents/ComboBox'
import { StatusContratoTypes, StatusParcelaTypes } from '../../ImportBackend/types/ConstantesDataTypes'

interface PesquisaInterface {
  recebimentoInicial: string
  recebimentoFinal: string
  vencimentoInicial: string
  vencimentoFinal: string
  emissaoInicial: string
  emissaoFinal: string
  unidades: Array<number>
  idRegional: number
  statusParcela: string
  statusContrato: string
}

interface RelatorioInterface {
  registros: Array<Record<any, any>>
  cabecalho: Array<DataTableCabecalhoInterface>
}

interface rsTotalizadorInterface {
  chave: string
  descricao: string
  qtd: number
  valor: number
}

export default function Parcelas () {

  const componentRef = useRef( null )

  const handlePrint = useReactToPrint( {
    content: () => componentRef.current,
  } )

  const handleDownload = () => {

    ( new ClsExportDataTableCSV() ).exportCSV( 'Produtividade', rsRelatorio.registros, rsRelatorio.cabecalho )

  }

  const clsFormatacao: ClsFormatacao = new ClsFormatacao()

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const CABECALHO_BASE: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'idContrato',
      cabecalho: 'Contrato',
      alinhamento: 'left'
    },
    {
      campo: 'emissao',
      cabecalho: 'Emissão',
      alinhamento: 'left',
      format: ( v: string ) => clsFormatacao.dataISOtoUser( v )
    },
    {
      campo: 'vencimento',
      cabecalho: 'Vencimento',
      alinhamento: 'left',
      format: ( v: string ) => clsFormatacao.dataISOtoUser( v )
    },
    {
      campo: 'nome',
      cabecalho: 'Nome',
      alinhamento: 'left',
    },
    {
      campo: 'fone',
      cabecalho: 'Fone',
      alinhamento: 'left',
    },
    {
      campo: 'whatsAPP',
      cabecalho: 'WhatsApp',
      alinhamento: 'left',
    },
    {
      campo: 'email',
      cabecalho: 'e-mail',
      alinhamento: 'left',
    },
    {
      campo: 'descricaoUnidade',
      cabecalho: 'Unidade',
      alinhamento: 'left',
    },
    {
      campo: 'descricaoRegional',
      cabecalho: 'Regional',
      alinhamento: 'left',
    },
    {
      campo: 'nomeResponsavelPreenchimento',
      cabecalho: 'Nome Responsável Preenchimento',
      alinhamento: 'left',
    },
    {
      campo: 'statusParcela',
      cabecalho: 'Status Parcela',
      alinhamento: 'left',
      format: ( v: string ) => StatusParcelaTypes.find( s => s.idStatus === v )?.descricao
    },
    {
      campo: 'statusContrato',
      cabecalho: 'Status Contrato',
      alinhamento: 'left',
      format: ( v: string ) => StatusContratoTypes.find( s => s.idStatus === v )?.descricao
    },
    {
      campo: 'valor',
      cabecalho: 'Valor',
      alinhamento: 'right',
      format: ( v: string ) => clsFormatacao.currency( parseFloat( v ) )
    },
    {
      campo: 'dataRecebimento',
      cabecalho: 'Data Recebimento',
      alinhamento: 'left',
      format: ( v: string ) => v ? clsFormatacao.dataISOtoUser( v ) : ''
    },
    {
      campo: 'vrRecebido',
      cabecalho: '$ Recebido',
      alinhamento: 'right',
      format: ( v: string ) => clsFormatacao.currency( parseFloat( v ) )
    },
    {
      campo: 'vrDescontoRecebido',
      cabecalho: '$ Desconto',
      alinhamento: 'right',
      format: ( v: string ) => clsFormatacao.currency( parseFloat( v ) )
    },
    {
      campo: 'vrJurosRecebido',
      cabecalho: '$ Juros',
      alinhamento: 'right',
      format: ( v: string ) => clsFormatacao.currency( parseFloat( v ) )
    },
    {
      campo: 'vrMultaRecebido',
      cabecalho: '$ Multa',
      alinhamento: 'right',
      format: ( v: string ) => clsFormatacao.currency( parseFloat( v ) )
    }/*,
    {
      campo: 'idParcelaCora',
      cabecalho: 'Cora',
      alinhamento: 'right',
    }
      */
  ]

  const [rsRelatorio, setRsRelatorio] = useState<RelatorioInterface>( {
    cabecalho: CABECALHO_BASE,
    registros: []
  } )

  const abortController: AbortController = new AbortController()

  const navigate = useNavigate()

  const btFechar = () => {
    navigate( '/' )
  }

  const [pesquisa, setPesquisa] = useState<PesquisaInterface>( {
    recebimentoFinal: '',
    recebimentoInicial: '',
    vencimentoInicial: '',
    vencimentoFinal: '',
    emissaoInicial: '',
    emissaoFinal: '',
    unidades: [],
    idRegional: 0,
    statusParcela: '',
    statusContrato: ''
  } )

  const validarPesquisa = (): boolean => {
    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    let retornoVencimento = clsValidacao.eData( 'vencimentoInicial', pesquisa, erros, retorno, true )
    retornoVencimento = clsValidacao.eData( 'vencimentoFinal', pesquisa, erros, retorno, true )

    let retornoEmissao = clsValidacao.eData( 'emissaoInicial', pesquisa, erros, retorno, true )
    retornoEmissao = clsValidacao.eData( 'emissaoFinal', pesquisa, erros, retorno, true )

    if ( retornoVencimento
      && pesquisa.vencimentoInicial.length > 0
      && pesquisa.vencimentoFinal.length > 0
      && pesquisa.vencimentoInicial > pesquisa.vencimentoFinal
    ) {
      erros.vencimentoInicial = 'Período Inválido!'
      erros.vencimentoFinal = 'Período Inválido!'
      retorno = false
    }

    if ( retornoEmissao
      && pesquisa.emissaoInicial.length > 0
      && pesquisa.emissaoFinal.length > 0
      && pesquisa.emissaoInicial > pesquisa.emissaoFinal
    ) {
      erros.emissaoInicial = 'Período Inválido!'
      erros.emissaoFinal = 'Período Inválido!'
      retorno = false
    }

    setErrosPesquisa( erros )

    retorno = retorno && retornoVencimento && retornoEmissao

    if ( retorno ) {
      consultarRelatorio()
    }

    return retorno
  }

  const cabecalhoTotalizador: Array<DataTableCabecalhoInterface> = [
    {
      cabecalho: 'Tipo',
      campo: 'descricao',
    },
    {
      cabecalho: 'QTD',
      campo: 'qtd',
      alinhamento: 'right',
      format: ( v: string ) => clsFormatacao.currency( parseFloat( v ), 0 )
    },
    {
      cabecalho: 'Vr. Total',
      campo: 'valor',
      alinhamento: 'right',
      format: ( v: string ) => clsFormatacao.currency( parseFloat( v ) )
    }
  ]

  const [rsTotalizador, setRsTotalizador] = useState<Array<rsTotalizadorInterface>>( [] )

  const totalizarRelatorio = ( jsonString: string ): Array<Record<any, any>> => {

    let retorno: Array<Record<any, any>> = JSON.parse( jsonString )

    let tmpTotalizador: Array<rsTotalizadorInterface> = [{
      chave: 'EM',
      descricao: 'Emitidos',
      qtd: 0,
      valor: 0
    },
    {
      chave: 'PG',
      descricao: 'Pagos',
      qtd: 0,
      valor: 0
    },
    {
      chave: 'CD',
      descricao: 'Cadastrados',
      qtd: 0,
      valor: 0
    },
    {
      chave: 'CA',
      descricao: 'Cancelados',
      qtd: 0,
      valor: 0
    },
    {
      chave: 'TT',
      descricao: 'Total',
      qtd: 0,
      valor: 0
    }]

    retorno.forEach( tot => {

      let indice = tmpTotalizador.findIndex( v => v.chave === tot.statusParcela )

      if ( indice >= 0 ) {
        tmpTotalizador[indice].qtd++
        tmpTotalizador[indice].valor += parseFloat( tot.valor )
      }

      tmpTotalizador[4].qtd++
      tmpTotalizador[4].valor += parseFloat( tot.valor )

    } )

    setRsTotalizador( tmpTotalizador )

    return retorno
  }

  const [errosPesquisa, setErrosPesquisa] = useState( {} )

  const consultarRelatorio = () => {
    const query = `
      relParcelas (
        recebimentoInicial: "${pesquisa.recebimentoInicial}",
        recebimentoFinal: "${pesquisa.recebimentoFinal}",
        vencimentoInicial: "${pesquisa.vencimentoInicial}",
        vencimentoFinal: "${pesquisa.vencimentoFinal}",
        emissaoInicial: "${pesquisa.emissaoInicial}",
        emissaoFinal: "${pesquisa.emissaoFinal}",
        unidades: ${JSON.stringify( pesquisa.unidades )},
        idRegional: ${pesquisa.idRegional},
        statusParcela: "${pesquisa.statusParcela}",
        statusContrato: "${pesquisa.statusContrato}"
      ) {
        ok
        mensagem
      }
    `

    const clsApi = new BackEndAPI()

    clsApi.query<RespostaPadraoInterface>( query, 'relParcelas', 'Gerando Relatório de Parcelas...', contexto, abortController ).then( rsOK => {

      if ( rsOK.ok ) {
        setRsRelatorio( {
          cabecalho: CABECALHO_BASE,
          registros: totalizarRelatorio( rsOK.mensagem )
        } )

      } else {
        setRsRelatorio( {
          cabecalho: CABECALHO_BASE,
          registros: []
        } )
      }

    } ).catch( ( e ) => {
      console.log( e.message )

      setMensagemState( {
        ...mensagemState,
        titulo: 'Erro! Consulte Suporte!',
        exibir: true,
        mensagem: 'Erro ao Gerar Relatório de Produtividade!',
        tipo: MensagemTipo.Error,
        exibirBotao: true
      } )

    } )
  }

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 5 }} >

        <Paper variant="outlined" sx={{ padding: 2 }} className='content' ref={componentRef} >

          <CabecalhoImpressao />

          <Grid container sx={{ display: 'flex', alignItems: 'center' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Relatório de Parcelas
                <Typography variant="body2" gutterBottom>
                  Dados Analíticos de Parcelas
                </Typography>
              </Typography>

              <IconButton onClick={() => btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Grid item xs={12} sx={{ mb: 3 }}>

              <Grid container sx={{ display: 'flex', alignItems: 'center' }}>

                <Grid item xs={6} sm={4} md={2} >

                  <InputText
                    dados={pesquisa}
                    field='emissaoInicial'
                    label='Início Emissão'
                    setState={setPesquisa}
                    erros={errosPesquisa}
                    tipo='date'
                    mask='00/00/0000'
                    textAlign="right"
                  />

                </Grid>

                <Grid item xs={6} sm={4} md={2} sx={{ pl: 1 }}>

                  <InputText
                    dados={pesquisa}
                    field='emissaoFinal'
                    label='Término Emissão'
                    setState={setPesquisa}
                    erros={errosPesquisa}
                    tipo='date'
                    mask='00/00/0000'
                    textAlign="right"
                  />

                </Grid>

                <Grid item xs={6} sm={4} md={2} sx={{ pl: { sm: 1 } }} >

                  <InputText
                    dados={pesquisa}
                    field='vencimentoInicial'
                    label='Início Vencimento'
                    setState={setPesquisa}
                    erros={errosPesquisa}
                    tipo='date'
                    mask='00/00/0000'
                    textAlign="right"
                  />

                </Grid>

                <Grid item xs={6} sm={4} md={2} sx={{ pl: { xs: 1, sm: 0, md: 1 } }}>

                  <InputText
                    dados={pesquisa}
                    field='vencimentoFinal'
                    label='Término Vencimento'
                    setState={setPesquisa}
                    erros={errosPesquisa}
                    tipo='date'
                    mask='00/00/0000'
                    textAlign="right"
                  />

                </Grid>

                <Grid item xs={6} sm={4} md={2} sx={{ pl: { sm: 1 } }} >

                  <InputText
                    dados={pesquisa}
                    field='recebimentoInicial'
                    label='Início Recebimento'
                    setState={setPesquisa}
                    erros={errosPesquisa}
                    tipo='date'
                    mask='00/00/0000'
                    textAlign="right"
                  />

                </Grid>

                <Grid item xs={6} sm={4} md={2} sx={{ pl: 1 }}>

                  <InputText
                    dados={pesquisa}
                    field='recebimentoFinal'
                    label='Término Recebimento'
                    setState={setPesquisa}
                    erros={errosPesquisa}
                    tipo='date'
                    mask='00/00/0000'
                    textAlign="right"
                  />

                </Grid>

                {/* INICIO */}

                <Grid item xs={6} sm={3} md={2} >
                  <ComboBox
                    campoDescricao='descricao'
                    campoID='idStatus'
                    dados={pesquisa}
                    field='statusContrato'
                    setState={setPesquisa}
                    erros={errosPesquisa}
                    label='Status do Contrato'
                    opcoes={StatusContratoTypes}
                    valorPadraoCampoEmBranco={''}
                  />

                </Grid>

                <Grid item xs={6} sm={3} md={2} sx={{ pl: 1 }}>
                  <ComboBox
                    campoDescricao='descricao'
                    campoID='idStatus'
                    dados={pesquisa}
                    field='statusParcela'
                    setState={setPesquisa}
                    erros={errosPesquisa}
                    label='Status da Parcela'
                    opcoes={StatusParcelaTypes}
                    valorPadraoCampoEmBranco={''}
                  />

                </Grid>

                <Grid item xs={12} sm={6} md={4} sx={{ pl: { sm: 1 } }} >

                  <PesquisarTabela<any>
                    setState={setPesquisa}
                    field='idRegional'
                    fieldSet='idRegional'
                    label='Regional'
                    dados={pesquisa}
                    campoQueryPesquisaID='idRegional'
                    campoQueryPesquisa='pesquisa'
                    camposRetornoQueryPesquisa='{idRegional, descricao}'
                    campoLabelQueryPesquisa='descricao'
                    nomeQueryPesquisa='getRegionais'
                    nomeQueryPesquisaID='getRegionalPorId'
                    mensagemPesquisa='Procurando Regional...'
                    erros={errosPesquisa}
                    pesquisarTudoAoIniciar={true}
                  />

                </Grid>

                <Grid item xs={12} sm={9} md={4} sx={{ pl: { md: 1 } }}>

                  <SelectMultiplo
                    dados={pesquisa}
                    field='unidades'
                    fieldIdSelect='idUnidade'
                    fieldSelect='descricao'
                    label='Unidades'
                    setState={setPesquisa}
                    pesquisaInicial={{
                      mensagem: 'Pesquisando Unidades',
                      tipo: 'Query',
                      pesquisa: 'getUsuarioUnidadesPermitidasPorAcesso {idUnidade, descricao}',
                      objRetorno: 'getUsuarioUnidadesPermitidasPorAcesso'
                    }
                    }
                  />

                </Grid>

                <Grid className="noprint" item xs={12} sm={3} md={12} sx={{ textAlign: 'right', mt: 4, pl: { sm: 1, md: 0 } }}>
                  <Button variant='contained' onClick={() => validarPesquisa()}>Procurar</Button>
                </Grid>

              </Grid>

            </Grid>

            <Grid item xs={12} sx={{ mt: 3 }}>
              <DataTable
                dados={rsRelatorio.registros}
                cabecalho={rsRelatorio.cabecalho}
              />
            </Grid>

            <Grid item xs={12} md={6} sx={{ mt: 3 }}>
              <DataTable
                dados={rsTotalizador}
                cabecalho={cabecalhoTotalizador}
              />
            </Grid>

          </Grid>

          <Condicional condicao={rsRelatorio.registros.length > 0}>
            <Grid className="noprint" item xs={12} sm={2} alignSelf='center' sx={{ mt: { xs: 0, sm: 2 }, textAlign: 'right' }}>
              <Button variant='contained' onClick={() => handlePrint()}>Imprimir</Button>
              <Button variant='contained' onClick={() => handleDownload()} sx={{ ml: 1 }}>Download</Button>
            </Grid>
          </Condicional>

        </Paper>

      </Container >

    </>
  )

}