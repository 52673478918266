import React, { useContext, useState, useRef } from 'react'
import { Button, Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import ClsFormatacao from '../../Utils/ClsFormatacao'
import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable'
import BackEndAPI from '../../Services/BackEndAPI'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import { MensagemTipo } from '../../GlobalStates/MensagemState'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import { RespostaPadraoInterface } from '../../ImportBackend/Interfaces/PadraoInterfaces'
import PesquisarTabela from '../../DevComponents/PesquisarTabela'
import InputText from '../../DevComponents/InputText'
import ClsValidacao from '../../Utils/ClsValidacao'

import { useReactToPrint } from 'react-to-print';
import Condicional from '../../Layout/Condicional'
import CabecalhoImpressao from '../../Layout/CabecalhoImpressao'
import SelectMultiplo from '../../DevComponents/SelectMultiplo'
import ClsExportDataTableCSV from '../../Utils/ClsExportDataTableCSV'
import ComboBox from '../../DevComponents/ComboBox'

interface PesquisaInterface {
  dataInicial: string
  dataFinal: string
  unidades: Array<number>
  idRegional: number
  agruparPor: string
}

interface RelatorioInterface {
  registros: Array<Record<any, any>>
  cabecalho: Array<DataTableCabecalhoInterface>
}

export default function MovimentacaoFinanceira () {

  const componentRef = useRef( null )

  const handlePrint = useReactToPrint( {
    content: () => componentRef.current,
  } )

  const handleDownload = () => {

    ( new ClsExportDataTableCSV() ).exportCSV( 'MovimentacaoFinanceira', rsRelatorio.registros, rsRelatorio.cabecalho )

  }

  const clsFormatacao: ClsFormatacao = new ClsFormatacao()

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const CABECALHO_BASE: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'anomes',
      cabecalho: 'Vencimento em',
      alinhamento: 'left',
      format: ( v: number ) => { let rs = v.toString(); return rs.substring( 4, 6 ).concat( '/' ).concat( rs.substring( 0, 4 ) ) }
    },
    {
      campo: 'vrRecebido',
      cabecalho: '$ Recebido',
      alinhamento: 'right',
      format: ( v: string ) => clsFormatacao.currency( parseFloat( v ) )
    },
    {
      campo: 'qtdRecebido',
      cabecalho: 'Qt Recebido',
      alinhamento: 'right'
    },
    {
      campo: 'vrAReceber',
      cabecalho: '$ A Receber',
      alinhamento: 'right',
      format: ( v: string ) => clsFormatacao.currency( parseFloat( v ) )
    },
    {
      campo: 'qtdAReceber',
      cabecalho: 'Qt A Receber',
      alinhamento: 'right'
    },
    {
      campo: 'vrAtrasado',
      cabecalho: '$ Em Atraso',
      alinhamento: 'right',
      format: ( v: string ) => clsFormatacao.currency( parseFloat( v ) )
    },
    {
      campo: 'qtdAtrasado',
      cabecalho: 'Qt Em Atraso',
      alinhamento: 'right'
    }
  ]

  const [rsRelatorio, setRsRelatorio] = useState<RelatorioInterface>( {
    cabecalho: CABECALHO_BASE,
    registros: []
  } )

  const abortController: AbortController = new AbortController()

  const navigate = useNavigate()

  const btFechar = () => {
    navigate( '/' )
  }

  const [pesquisa, setPesquisa] = useState<PesquisaInterface>( {
    dataInicial: '',
    dataFinal: '',
    unidades: [],
    idRegional: 0,
    agruparPor: 'pe'
  } )

  const validarPesquisa = (): boolean => {
    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.eData( 'dataInicial', pesquisa, erros, retorno, true )
    retorno = clsValidacao.eData( 'dataFinal', pesquisa, erros, retorno, true )

    if ( retorno
      && pesquisa.dataInicial.length > 0
      && pesquisa.dataFinal.length > 0
      && pesquisa.dataInicial > pesquisa.dataFinal
    ) {
      erros.dataInicial = 'Período Inválido!'
      erros.dataFinal = 'Período Inválido!'
      retorno = false
    }

    setErrosPesquisa( erros )

    if ( retorno ) {
      consultarRelatorio()
    }

    return retorno
  }

  const [errosPesquisa, setErrosPesquisa] = useState( {} )

  const consultarRelatorio = () => {
    const query = `
      relMovimentacaoFinanceira (
        dataInicial: "${pesquisa.dataInicial}",
        dataFinal: "${pesquisa.dataFinal}",
        unidades: ${JSON.stringify( pesquisa.unidades )},
        idRegional: ${pesquisa.idRegional},
        agruparPor: "${pesquisa.agruparPor}"
      ) {
        ok
        mensagem
      }
    `

    const clsApi = new BackEndAPI()

    clsApi.query<RespostaPadraoInterface>( query, 'relMovimentacaoFinanceira', 'Gerando Relatório de Movimentação Financeira...', contexto, abortController ).then( rsOK => {

      if ( rsOK.ok ) {

        let cabecalho = CABECALHO_BASE

        if ( pesquisa.agruparPor === 'un' ) {
          cabecalho.splice( 1, 0,
            {
              campo: 'descricaoUnidade',
              cabecalho: 'Unidade',
              alinhamento: 'left',
            } )
        } else if ( pesquisa.agruparPor === 're' ) {
          cabecalho.splice( 1, 0,
            {
              campo: 'descricaoRegional',
              cabecalho: 'Regional',
              alinhamento: 'left',
            } )
        } else if ( pesquisa.agruparPor === 'rp' ) {
          cabecalho.splice( 1, 0,
            {
              campo: 'nomeResponsavelPreenchimento',
              cabecalho: 'Responsável Preenchimento',
              alinhamento: 'left',
            },
            {
              campo: 'cpfResponsavelPreenchimento',
              cabecalho: 'CPF',
              alinhamento: 'left',
            }
          )
        }

        // console.log(rsOK.mensagem)

        setRsRelatorio( {
          cabecalho: cabecalho,
          registros: JSON.parse( rsOK.mensagem )
        } )

      } else {
        setRsRelatorio( {
          cabecalho: CABECALHO_BASE,
          registros: []
        } )
      }

    } ).catch( ( e ) => {
      console.log( e.message )

      setMensagemState( {
        ...mensagemState,
        titulo: 'Erro! Consulte Suporte!',
        exibir: true,
        mensagem: 'Erro ao Gerar Relatório de Produtividade!',
        tipo: MensagemTipo.Error,
        exibirBotao: true
      } )

    } )
  }

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 5 }} >

        <Paper variant="outlined" sx={{ padding: 2 }} className='content' ref={componentRef} >

          <CabecalhoImpressao />

          <Grid container sx={{ display: 'flex', alignItems: 'center' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Relatório de Movimentação Financeira
                <Typography variant="body2" gutterBottom>
                  Valores Emitidos, A Receber e Atrasados
                </Typography>
              </Typography>

              <IconButton onClick={() => btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Grid item xs={12} sx={{ mb: 3 }}>

              <Grid container sx={{ display: 'flex', alignItems: 'center' }}>

                <Grid item xs={6} sm={3} md={2} >

                  <InputText
                    dados={pesquisa}
                    field='dataInicial'
                    label='Início'
                    setState={setPesquisa}
                    erros={errosPesquisa}
                    tipo='date'
                    mask='00/00/0000'
                    textAlign="right"
                  />

                </Grid>

                <Grid item xs={6} sm={3} md={2} sx={{ pl: { xs: 1 } }}>

                  <InputText
                    dados={pesquisa}
                    field='dataFinal'
                    label='Término'
                    setState={setPesquisa}
                    erros={errosPesquisa}
                    tipo='date'
                    mask='00/00/0000'
                    textAlign="right"
                  />

                </Grid>

                <Grid item xs={12} sm={6} md={3} sx={{ pl: { sm: 1 } }}>
                  <ComboBox
                    campoDescricao='descricao'
                    campoID='id'
                    dados={pesquisa}
                    field='agruparPor'
                    setState={setPesquisa}
                    erros={errosPesquisa}
                    label='Agrupar'
                    opcoes={
                      [
                        { id: 'pe', descricao: 'Período' },
                        { id: 'un', descricao: 'Unidade' },
                        { id: 're', descricao: 'Regional' } //,
                        // { id: 'rp', descricao: 'Preenchimento' },
                      ]}
                  />

                </Grid>

                <Grid item xs={12} md={5} sx={{ pl: { md: 1 } }} >

                  <PesquisarTabela<any>
                    setState={setPesquisa}
                    field='idRegional'
                    fieldSet='idRegional'
                    label='Regional'
                    dados={pesquisa}
                    campoQueryPesquisaID='idRegional'
                    campoQueryPesquisa='pesquisa'
                    camposRetornoQueryPesquisa='{idRegional, descricao}'
                    campoLabelQueryPesquisa='descricao'
                    nomeQueryPesquisa='getRegionais'
                    nomeQueryPesquisaID='getRegionalPorId'
                    mensagemPesquisa='Procurando Regional...'
                    erros={errosPesquisa}
                    pesquisarTudoAoIniciar={true}
                  />

                </Grid>

                <Grid item xs={12} >

                  <SelectMultiplo
                    dados={pesquisa}
                    field='unidades'
                    fieldIdSelect='idUnidade'
                    fieldSelect='descricao'
                    label='Unidades'
                    setState={setPesquisa}
                    pesquisaInicial={{
                      mensagem: 'Pesquisando Unidades',
                      tipo: 'Query',
                      pesquisa: 'getUsuarioUnidadesPermitidasPorAcesso {idUnidade, descricao}',
                      objRetorno: 'getUsuarioUnidadesPermitidasPorAcesso'
                    }
                    }
                  />

                </Grid>

                <Grid className="noprint" item xs={6} sx={{ textAlign: 'left', mt: 3 }}>
                  <Button variant='contained' onClick={() => validarPesquisa()}>Procurar</Button>
                </Grid>

              </Grid>

            </Grid>

            <Grid item xs={12} sx={{ mt: 3 }}>
              <DataTable
                dados={rsRelatorio.registros}
                cabecalho={rsRelatorio.cabecalho}
              />
            </Grid>

          </Grid>

          <Condicional condicao={rsRelatorio.registros.length > 0}>
            <Grid className="noprint" item xs={12} sm={2} alignSelf='center' sx={{ mt: { xs: 0, sm: 2 }, textAlign: 'right' }}>
              <Button variant='contained' onClick={() => handlePrint()}>Imprimir</Button>
              <Button variant='contained' onClick={() => handleDownload()} sx={{ ml: 1 }}>Download</Button>
            </Grid>
          </Condicional>

        </Paper>

      </Container>

    </>
  )

}