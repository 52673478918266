import React, { useContext, useState } from 'react'
import { Container, Grid, Icon, IconButton, Paper, Typography } from '@mui/material'
import InputText from '../../DevComponents/InputText'
import { ContaCorrenteInputInterface, ContaCorrenteInterface } from '../../ImportBackend/Interfaces/ContaCorrenteInterfaces'
import BackEndAPI from '../../Services/BackEndAPI'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import Condicional from '../../Layout/Condicional'
import Button from '@mui/material/Button'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { clsUtils } from 'zlib-utils'
import { RespostaPadraoInterface } from '../../ImportBackend/Interfaces/PadraoInterfaces'
import { MensagemTipo } from '../../GlobalStates/MensagemState'

import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable'
import { useNavigate } from 'react-router-dom'
import ClsValidacao from '../../Utils/ClsValidacao'
import PesquisarTabela from '../../DevComponents/PesquisarTabela'
import ClsFormatacao from '../../Utils/ClsFormatacao'

interface PesquisaInterface {
  dataInicial: string
  dataFinal: string
  idUnidade: number
}

enum StatusForm {
  Incluindo,
  Excluindo,
  Pesquisando,
  Editando
}

export default function ContaCorrente () {

  const [statusForm, setStatusForm] = useState<StatusForm>( StatusForm.Pesquisando )

  const clsFormatacao: ClsFormatacao = new ClsFormatacao()

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    /*
            idContaCorrente
            idContratoParcela
            idUnidade
            descricao
            debito
            credito
            saldoGeral
            saldoUnidade
            dataMovimentacao
            dataConciliacao
    */
    {
      campo: 'dataMovimentacao',
      cabecalho: 'Data',
      alinhamento: 'left',
      format: ( v ) => clsFormatacao.dataISOtoUser( v )
    },
    {
      campo: 'descricao',
      cabecalho: 'Descrição',
      alinhamento: 'left'
    },
    {
      campo: 'Unidade',
      cabecalho: 'Unidade',
      alinhamento: 'left',
      format: ( v ) => v.descricao
    },
    {
      campo: 'debito',
      cabecalho: 'Débito',
      alinhamento: 'right',
      format: ( v: number ) => clsFormatacao.currency( v )
    },
    {
      campo: 'credito',
      cabecalho: 'Crédito',
      alinhamento: 'right',
      format: ( v: number ) => clsFormatacao.currency( v )
    },
    {
      campo: 'saldoUnidade',
      cabecalho: 'Saldo da Unidade',
      alinhamento: 'right',
      format: ( v: number ) => clsFormatacao.currency( v )
    },
    {
      campo: 'saldoGeral',
      cabecalho: 'Saldo da Conta',
      alinhamento: 'right',
      format: ( v: number ) => clsFormatacao.currency( v )
    },
  ]

  const ResetDados: ContaCorrenteInputInterface = {
    idContaCorrente: 0,
    idUnidade: 0,
    descricao: '',
    debito: 0,
    credito: 0,
    dataMovimentacao: ''
  }

  const TituloForm = {
    [StatusForm.Incluindo]: 'Nova Movimentação',
    [StatusForm.Excluindo]: 'Cancelar Movimentação',
    [StatusForm.Pesquisando]: 'Consultar Movimentação',
    [StatusForm.Editando]: 'Alterando Movimentação',
  }

  const clsApi = new BackEndAPI()

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const [dados, setDados] = useState<ContaCorrenteInputInterface>( ResetDados )

  const [erros, setErros] = useState( {} )
  const [errosPesquisa, setErrosPesquisa] = useState( {} )

  const [pesquisa, setPesquisa] = useState<PesquisaInterface>( {
    dataInicial: '',
    dataFinal: '',
    idUnidade: 0
  } )

  const [rsPesquisa, setRsPesquisa] = useState<Array<ContaCorrenteInterface>>( [] )

  const navigate = useNavigate()

  const btFechar = () => {
    navigate( '/' )
  }

  const btIncluir = () => {
    setDados( ResetDados )
    setErros( {} )
    setStatusForm( StatusForm.Incluindo )
  }

  const btEditar = ( rs: ContaCorrenteInterface ) => {
    setDados( {
      credito: rs.credito,
      dataMovimentacao: rs.dataMovimentacao,
      debito: rs.debito,
      descricao: rs.descricao,
      idUnidade: rs.idUnidade,
      idContaCorrente: rs.idContaCorrente
    } )
    setStatusForm( StatusForm.Editando )
  }

  const btExcluir = ( rs: ContaCorrenteInterface ) => {
    setDados( rs )
    setStatusForm( StatusForm.Excluindo )
  }

  const btCancelar = () => {
    setDados( ResetDados )
    setErros( {} )
    setStatusForm( StatusForm.Pesquisando )
  }

  const validarDados = (): boolean => {
    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'descricao', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'idUnidade', dados, erros, retorno )
    retorno = clsValidacao.eData( 'dataMovimentacao', dados, erros, retorno )

    // Débito ou Crédito não podem ser vazio (XOR)
    if ( !( ( dados.credito || dados.debito ) && !( dados.credito && dados.debito ) ) ) {
      retorno = false
      erros.credito = 'Crédito ou Débito'
      erros.debito = 'Débito ou Crédito'
    }

    setErros( erros )

    return retorno
  }

  const validarPesquisa = (): boolean => {
    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.eData( 'dataInicial', pesquisa, erros, retorno, true )
    retorno = clsValidacao.eData( 'dataFinal', pesquisa, erros, retorno, true )

    if ( retorno
      && pesquisa.dataInicial.length > 0
      && pesquisa.dataFinal.length > 0
      && pesquisa.dataInicial > pesquisa.dataFinal
    ) {
      erros.dataInicial = 'Período Inválido!'
      erros.dataFinal = 'Período Inválido!'
      retorno = false
    }

    setErrosPesquisa( erros )

    return retorno
  }

  const btConfirmar = () => {
    if ( validarDados() ) {
      const mutation: string = `
        updateContaCorrente(dados: ${clsUtils.ConverterEmGql( dados )}) {
          ok
          mensagem
        }
      `

      clsApi.mutation<RespostaPadraoInterface>( mutation, 'updateContaCorrente', 'Atualizando Conta Corrente...', contexto ).then( rs => {

        if ( rs.ok ) {
          setMensagemState( {
            ...mensagemState,
            titulo: 'Confirmado!',
            exibir: true,
            mensagem: statusForm === StatusForm.Incluindo ? 'Operação Cadastrada com Sucesso!' : 'Dados Alterados!',
            tipo: MensagemTipo.Info,
            exibirBotao: true
          } )

          setDados( ResetDados )
          setStatusForm( StatusForm.Pesquisando )
          onClickPesquisa()
        } else {

          setMensagemState( {
            ...mensagemState,
            exibir: true,
            mensagem: rs.mensagem,
            tipo: MensagemTipo.Error,
            exibirBotao: true
          } )

        }

      } ).catch( () => {

        setMensagemState( {
          ...mensagemState,
          exibir: true,
          mensagem: 'Operação Não Atualizado!',
          tipo: MensagemTipo.Error,
          exibirBotao: true
        } )

      } )

    }
  }

  const btConfirmarExclusao = () => {
    const mutation: string = `
        delContaCorrente(idContaCorrente: ${dados.idContaCorrente}) {
          ok
          mensagem
        }
      `

    clsApi.mutation<RespostaPadraoInterface>( mutation, 'delContaCorrente', 'Cancelando Operação...', contexto ).then( rs => {

      if ( rs.ok ) {
        setMensagemState( {
          ...mensagemState,
          titulo: 'Confirmado!',
          exibir: true,
          mensagem: 'Operação Cancelada com Sucesso!',
          tipo: MensagemTipo.Info,
          exibirBotao: true
        } )

        setDados( ResetDados )
        setStatusForm( StatusForm.Pesquisando )
        onClickPesquisa()

      } else {

        setMensagemState( {
          ...mensagemState,
          titulo: 'Erro',
          exibir: true,
          mensagem: rs.mensagem,
          tipo: MensagemTipo.Error,
          exibirBotao: true
        } )

      }

    } ).catch( () => {

      setMensagemState( {
        ...mensagemState,
        titulo: 'Erro',
        exibir: true,
        mensagem: 'Operação Não Cancelada!',
        tipo: MensagemTipo.Error,
        exibirBotao: true
      } )

    } )

  }

  const onClickPesquisa = () => {

    if ( validarPesquisa() ) {

      const query = `
      getContaCorrente(
        dataInicial: "${pesquisa.dataInicial}",
        dataFinal: "${pesquisa.dataFinal}",
        idUnidade: ${pesquisa.idUnidade})
      {
        idContaCorrente
        idContratoParcela
        idUnidade
        Unidade {
          descricao
        }
        descricao
        debito
        credito
        saldoGeral
        saldoUnidade
        dataMovimentacao
        dataConciliacao
      }
    `

      clsApi.query<Array<ContaCorrenteInterface>>( query, 'getContaCorrente', 'Pequisando Movimentação...', contexto ).then( rsContaCorrente => {

        setRsPesquisa( rsContaCorrente )

      } ).catch( () => {

        setMensagemState( {
          ...mensagemState,
          titulo: 'Erro! Consulte Suporte!',
          exibir: true,
          mensagem: 'Erro ao Consultar ContaCorrente!',
          tipo: MensagemTipo.Error,
          exibirBotao: true
        } )

      } )

    }

  }

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'center' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Movimentação de Conta Corrente
                <Typography variant="body2" gutterBottom>
                  {TituloForm[statusForm]}
                </Typography>
              </Typography>

              <IconButton onClick={() => btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>

              <Grid item xs={12} sx={{ mb: 3 }}>

                <Grid container sx={{ display: 'flex', alignItems: 'center' }}>

                  <Grid item xs={12} >

                    <PesquisarTabela<any>
                      setState={setPesquisa}
                      field='idUnidade'
                      fieldSet='idUnidade'
                      label='Unidade'
                      dados={pesquisa}
                      campoQueryPesquisaID='idUnidade'
                      campoQueryPesquisa='pesquisa'
                      camposRetornoQueryPesquisa='{idUnidade, descricao}'
                      campoLabelQueryPesquisa='descricao'
                      nomeQueryPesquisa='getUnidades'
                      nomeQueryPesquisaID='getUnidadePorId'
                      mensagemPesquisa='Procurando Unidade...'
                      erros={errosPesquisa}
                      pesquisarTudoAoIniciar={true}
                    />

                  </Grid>

                  <Grid item xs={12} sm={6}>

                    <InputText
                      dados={pesquisa}
                      field='dataInicial'
                      label='Início'
                      setState={setPesquisa}
                      erros={errosPesquisa}
                      tipo='date'
                      mask='00/00/0000'
                      textAlign="right"
                    />

                  </Grid>

                  <Grid item xs={12} sm={6} sx={{ pl: { sm: 1 } }}>

                    <InputText
                      dados={pesquisa}
                      field='dataFinal'
                      label='Término'
                      setState={setPesquisa}
                      erros={errosPesquisa}
                      tipo='date'
                      mask='00/00/0000'
                      textAlign="right"
                    />

                  </Grid>

                  <Grid item xs={6} sx={{ textAlign: 'left', mt: 3 }}>
                    <Button variant='contained' onClick={() => btIncluir()}>Incluir</Button>
                  </Grid>

                  <Grid item xs={6} sx={{ textAlign: 'right', mt: 3 }}>
                    <Button variant='contained' onClick={() => onClickPesquisa()}>Pesquisar</Button>
                  </Grid>

                </Grid>

              </Grid>


            </Condicional>

            <Condicional condicao={statusForm !== StatusForm.Pesquisando}>

              <Grid item xs={12} sm={8} lg={10}>

                <PesquisarTabela<any>
                  setState={setDados}
                  field='idUnidade'
                  fieldSet='idUnidade'
                  label='Unidade'
                  dados={dados}
                  campoQueryPesquisaID='idUnidade'
                  campoQueryPesquisa='pesquisa'
                  camposRetornoQueryPesquisa='{idUnidade, descricao}'
                  campoLabelQueryPesquisa='descricao'
                  nomeQueryPesquisa='getUnidades'
                  nomeQueryPesquisaID='getUnidadePorId'
                  mensagemPesquisa='Procurando Unidade...'
                  erros={erros}
                  pesquisarTudoAoIniciar={false}
                />

              </Grid>

              <Grid item xs={12} sm={4} lg={2} sx={{ pl: { sm: 1 } }} >

                <InputText
                  dados={dados}
                  field='dataMovimentacao'
                  label='Data'
                  setState={setDados}
                  erros={erros}
                  tipo='date'
                  mask='00/00/0000'
                  textAlign="right"
                />

              </Grid>

              <Grid item xs={12} sm={6} lg={8}>

                <InputText
                  dados={dados}
                  field='descricao'
                  label='Descrição'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={255}
                  tipo='uppercase'
                />

              </Grid>

              <Grid item xs={6} sm={3} lg={2} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='debito'
                  label='Débito'
                  setState={setDados}
                  erros={erros}
                  tipo='currency'
                  textAlign="right"
                />

              </Grid>

              <Grid item xs={6} sm={3} lg={2} sx={{ pl: 1 }}>

                <InputText
                  dados={dados}
                  field='credito'
                  label='Crédito'
                  setState={setDados}
                  erros={erros}
                  tipo='currency'
                  textAlign="right"
                />

              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>

                <Condicional condicao={statusForm === StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => btConfirmarExclusao()}>Confirmar</Button>
                </Condicional>

                <Condicional condicao={statusForm !== StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => btConfirmar()}>Confirmar</Button>
                </Condicional>

                <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => btCancelar()}>Cancelar</Button>

              </Grid>

            </Condicional>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <DataTable dados={rsPesquisa} cabecalho={Cabecalho} acoes={[
                  { icone: 'delete', toolTip: 'Excluir', onAcionador: btExcluir },
                  { icone: 'create', toolTip: 'Editar', onAcionador: btEditar }]
                } />
              </Grid>
            </Condicional>

          </Grid>
        </Paper>

      </Container>
    </>
  )
}