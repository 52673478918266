import React from 'react'

export default function CabecalhoImpressao () {

  return (
    <>
      <div className='printOnly headerRight'>
      </div>
    </>
  )

}