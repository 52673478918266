import React, { useContext, useState, useEffect } from 'react'
import { Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import InputText from '../../DevComponents/InputText'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import Condicional from '../../Layout/Condicional'
import Button from '@mui/material/Button'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import CreateIcon from '@mui/icons-material/Create'

import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable'
import { useLocation, useNavigate } from 'react-router-dom'
import { ClienteInterface } from '../../ImportBackend/Interfaces/ClienteInterfaces'
import ClsCrud from '../../Utils/ClsCrud'

// import { StatusForm } from '../../Utils/ClsCrud'
import ClsValidacao from '../../Utils/ClsValidacao'
import ClsCep from '../../Utils/ClsCep'
import PesquisarTabela from '../../DevComponents/PesquisarTabela'
import { RespostaPadraoInterface } from '../../ImportBackend/Interfaces/PadraoInterfaces'
import BackEndAPI from '../../Services/BackEndAPI'
import ClientesContratos from './ClientesContratos'

enum StatusForm {
  Incluindo,
  Excluindo,
  Pesquisando,
  Editando,
  Contrato
}

interface PesquisaInterface {
  descricao: string
}

export default function Clientes () {

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { loginState } = contexto
  const { mensagemState, setMensagemState } = contexto

  const [statusForm, setStatusForm] = useState<StatusForm>( StatusForm.Pesquisando )

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'nome',
      cabecalho: 'Nome',
      alinhamento: 'left'
    },
    {
      campo: 'fone',
      cabecalho: 'Fone',
      alinhamento: 'left'
    },
    {
      campo: 'WhatsAPP',
      cabecalho: 'WhatsApp',
      alinhamento: 'left'
    },
    {
      campo: 'cpf',
      cabecalho: 'CPF',
      alinhamento: 'left'
    },
    {
      campo: 'cidade',
      cabecalho: 'Cidade',
      alinhamento: 'left'
    },
    {
      campo: 'bairro',
      cabecalho: 'Bairro',
      alinhamento: 'left'
    },
    {
      campo: 'endereco',
      cabecalho: 'Endereço',
      alinhamento: 'left'
    }
  ]

  const ResetDados: ClienteInterface =
  {
    idCliente: 0,
    nome: '',
    bairro: '',
    cep: '',
    cidade: '',
    cpf: '',
    endereco: '',
    uf: '',
    complemento: '',
    dataNascimento: '',
    email: '',
    fone: '',
    idUnidade: loginState.idUnidadeAtual,
    numero: '',
    rg: '',
    rgEmissor: '',
    whatsAPP: ''
  }

  const TituloForm = {
    [StatusForm.Incluindo]: 'Inclusão de Nova Cliente',
    [StatusForm.Excluindo]: 'Exclusão de Cliente Não Utilizado',
    [StatusForm.Pesquisando]: 'Clientes são utilizadas Cadastro de Boletos',
    [StatusForm.Editando]: 'Alteração de Dados de Clientes',
    [StatusForm.Contrato]: 'Contratos do Cliente'
  }

  const [dados, setDados] = useState<ClienteInterface>( ResetDados )

  const [erros, setErros] = useState( {} )

  const [pesquisa, setPesquisa] = useState<PesquisaInterface>( { descricao: '' } )

  const [rsPesquisa, setRsPesquisa] = useState<Array<ClienteInterface>>( [] )

  const navigate = useNavigate()

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'idUnidade', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'nome', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'cep', dados, erros, retorno )

    retorno = clsValidacao.tamanho( 'endereco', dados, erros, retorno, false, 0, 50 )
    retorno = clsValidacao.tamanho( 'numero', dados, erros, retorno, false, 0, 10 )
    retorno = clsValidacao.tamanho( 'cidade', dados, erros, retorno, false, 0, 35 )
    retorno = clsValidacao.tamanho( 'bairro', dados, erros, retorno, false, 0, 30 )

    // retorno = clsValidacao.naoVazio( 'numero', dados, erros, retorno )
    // retorno = clsValidacao.naoVazio( 'bairro', dados, erros, retorno )
    // retorno = clsValidacao.naoVazio( 'cidade', dados, erros, retorno )
    retorno = clsValidacao.eUF( 'uf', dados, erros, retorno )
    retorno = clsValidacao.eCPF( 'cpf', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'rg', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'rgEmissor', dados, erros, retorno )
    retorno = clsValidacao.eTelefone( 'fone', dados, erros, retorno, true )
    retorno = clsValidacao.eTelefone( 'whatsAPP', dados, erros, retorno, true )
    retorno = clsValidacao.eEmail( 'email', dados, erros, retorno )
    retorno = clsValidacao.eData( 'dataNascimento', dados, erros, retorno )

    setErros( erros )

    return retorno

  }

  const clsCrud: ClsCrud<ClienteInterface> = new ClsCrud(
    navigate,
    ResetDados,
    setStatusForm as any,
    setDados,
    setErros,
    mensagemState,
    setMensagemState,
    setRsPesquisa,
    contexto,
    validarDados,
    {
      confirmarMutation: 'updateCliente',
      excluirMutation: 'delCliente',
      campoId: 'idCliente',
      camposPesquisa: '{idCliente nome fone whatsAPP cpf bairro cidade endereco}',
      pesquisaQuery: 'getClientes',
      pesquisaPorId: 'getClientePorId',
      camposPesquisaPorId: '{idCliente idUnidade nome cep endereco numero complemento bairro cidade uf dataNascimento cpf rg rgEmissor fone whatsAPP email}'
    },
    {
      confirmando: 'Atualizando Cliente',
      erroCadastro: 'Erro ao Cadastrar Cliente',
      erroExclusao: 'Erro ao Excluir Cliente',
      erroPesquisa: 'Erro ao Pesquisar Cliente',
      pesquisando: 'Pesquisando Dados de Clientes...',
      sucessoCadastro: 'Cliente Cadastrado com Sucesso!',
      atualizacaoSucesso: 'Cliente Atualizado com Sucesso!',
      tituloConfirmado: 'Confirmado!',
      sucessoExclusao: 'Cliente Excluído com Sucesso...',
      tituloConfirmacaoExclusao: 'Confirma?',
      tituloErroCadastro: 'Erro!',
      tituloErroExclusao: 'Erro!',
      tituloErroPesquisa: 'Erro!',
      excluindo: 'Excluindo Cliente...'
    }
  )

  const onKeyPesquisa = () => {
    clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )
  }

  const alterarCep = ( novoCep: any ): void => {

    const clsCep: ClsCep = new ClsCep()

    clsCep.alterarCep( novoCep, setMensagemState, setDados, dados, false, true )

  }

  const pesquisarCPF = ( cpf: string ): Promise<RespostaPadraoInterface> => {
    const query: string = `
      chkCpfDuplicado(idCliente: ${dados.idCliente}, cpf: "${cpf}") {
        ok
        mensagem
      }
    `

    const clsApi = new BackEndAPI()

    return clsApi.query<RespostaPadraoInterface>( query, 'chkCpfDuplicado', 'Pesquisando CPF...', contexto )

  }

  const chkCpfDuplicado = ( cpf: string ) => {
    let clsValidacao: ClsValidacao = new ClsValidacao()
    let erros: { [key: string]: string } = {}

    if ( cpf.length === 14 ) {

      if ( clsValidacao.eCPF( 'cpf', { cpf: cpf }, erros, true ) ) {
        pesquisarCPF( cpf ).then( rs => {
          if ( rs.ok ) {
            setErros( erros )
          } else {
            erros.cpf = 'CPF Duplicado!!!!'
            setErros( erros )
          }
        } )
        setDados( { ...dados, cpf: cpf } )
      } else {
        setDados( { ...dados, cpf: cpf } )
        setErros( erros )
      }

    }

  }

  const btContrato = ( rs: ClienteInterface ) => {
    clsCrud.pesquisarPorId( rs.idCliente as number, mensagemState ).then( () => {
      setStatusForm( StatusForm.Contrato )
    } )
  }

  const btContratoEditar = () => {
    clsCrud.btEditar( dados )
  }

  const btContratoCancelar = () => {
    clsCrud.btCancelar()
  }

  const location = useLocation()

  useEffect( () => {

    if ( location && location.state && location.state.pesquisaInicial && location.state.pesquisaInicial.length > 0 ) {
      clsCrud.onClickPesquisa( location.state.pesquisaInicial, mensagemState )
      setPesquisa( { descricao: location.state.pesquisaInicial } )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Cadastro de Clientes
                <Typography variant="body2" gutterBottom>
                  {TituloForm[statusForm]}
                </Typography>
              </Typography>

              <div>
                <Condicional condicao={statusForm === StatusForm.Contrato}>
                  <IconButton onClick={() => btContratoEditar()}>
                    <CreateIcon />
                  </IconButton>
                </Condicional>

                <IconButton onClick={() => clsCrud.btFechar()}>
                  <CloseIcon />
                </IconButton>
              </div>
            </Grid>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>

              <Grid item xs={12} sm={10} sx={{ mb: 3 }}>

                <InputText
                  dados={pesquisa}
                  field='descricao'
                  label='Pesquisar'
                  setState={setPesquisa}
                  iconeEnd="search"
                  onClickIconeEnd={() => clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )}
                  mapKeyPress={[{ key: 'Enter', onKey: onKeyPesquisa }]}
                />

              </Grid>

              <Grid item xs={12} sm={2} alignSelf='center' sx={{ mt: { xs: 0, sm: 2 }, textAlign: { xs: 'right', sm: 'center' } }}>
                <Button variant='contained' onClick={() => clsCrud.btIncluir()}>Incluir</Button>
              </Grid>

            </Condicional>

            <Condicional condicao={statusForm !== StatusForm.Pesquisando}>

              <Grid item xs={12} sm={5}>

                <InputText
                  dados={dados}
                  field='cpf'
                  label='CPF'
                  setState={setDados}
                  disabled={statusForm !== StatusForm.Incluindo}
                  erros={erros}
                  maxLength={14}
                  mask='cpf'
                  onChange={( cpf: string ) => chkCpfDuplicado( cpf )}
                />

              </Grid>

              <Grid item xs={12} sm={5} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='rg'
                  label='RG'
                  setState={setDados}
                  disabled={[StatusForm.Contrato, StatusForm.Excluindo].includes( statusForm )}
                  erros={erros}
                  maxLength={20}
                  tipo="uppercase"
                />

              </Grid>

              <Grid item xs={12} sm={2} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='rgEmissor'
                  label='Emissor'
                  setState={setDados}
                  disabled={[StatusForm.Contrato, StatusForm.Excluindo].includes( statusForm )}
                  erros={erros}
                  maxLength={10}
                  tipo="uppercase"
                />

              </Grid>

              <Grid item xs={12} sm={9}>

                <InputText
                  dados={dados}
                  field='nome'
                  label='Nome'
                  setState={setDados}
                  disabled={[StatusForm.Contrato, StatusForm.Excluindo].includes( statusForm )}
                  erros={erros}
                  maxLength={50}
                  tipo="uppercase"
                />

              </Grid>

              <Grid item xs={12} sm={3} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='dataNascimento'
                  label='Data Nascimento'
                  setState={setDados}
                  disabled={[StatusForm.Contrato, StatusForm.Excluindo].includes( statusForm )}
                  erros={erros}
                  tipo='date'
                  mask='00/00/0000'
                />

              </Grid>

              <Grid item xs={12} sm={4}>

                <InputText
                  dados={dados}
                  field='cep'
                  label='Cep'
                  setState={setDados}
                  disabled={[StatusForm.Contrato, StatusForm.Excluindo].includes( statusForm )}
                  erros={erros}
                  mask='cep'
                  onChange={( novoCep ) => alterarCep( novoCep )}
                />

              </Grid>

              <Grid item sm={8}></Grid>

              <Grid item xs={12} sm={7}>

                <InputText
                  dados={dados}
                  field='endereco'
                  label='Endereço'
                  setState={setDados}
                  disabled={[StatusForm.Contrato, StatusForm.Excluindo].includes( statusForm )}
                  erros={erros}
                  maxLength={50}
                  tipo="uppercase"
                />

              </Grid>

              <Grid item xs={12} sm={2} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='numero'
                  label='Número'
                  setState={setDados}
                  disabled={[StatusForm.Contrato, StatusForm.Excluindo].includes( statusForm )}
                  erros={erros}
                  maxLength={10}
                />

              </Grid>

              <Grid item xs={12} sm={3} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='complemento'
                  label='Complemento'
                  setState={setDados}
                  disabled={[StatusForm.Contrato, StatusForm.Excluindo].includes( statusForm )}
                  erros={erros}
                  maxLength={10}
                  tipo="uppercase"
                />

              </Grid>

              <Grid item xs={12} sm={5}>

                <InputText
                  dados={dados}
                  field='bairro'
                  label='Bairro'
                  setState={setDados}
                  disabled={[StatusForm.Contrato, StatusForm.Excluindo].includes( statusForm )}
                  erros={erros}
                  maxLength={30}
                  tipo="uppercase"
                />

              </Grid>

              <Grid item xs={12} sm={5} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='cidade'
                  label='Cidade'
                  setState={setDados}
                  disabled={[StatusForm.Contrato, StatusForm.Excluindo].includes( statusForm )}
                  erros={erros}
                  maxLength={35}
                  tipo="uppercase"
                />

              </Grid>

              <Grid item xs={12} sm={2} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='uf'
                  label='UF'
                  setState={setDados}
                  disabled={[StatusForm.Contrato, StatusForm.Excluindo].includes( statusForm )}
                  erros={erros}
                  mask='uf'
                  tipo='uppercase'
                />

              </Grid>

              <Grid item xs={12} sm={6}>

                <InputText
                  dados={dados}
                  field='fone'
                  label='Fone'
                  setState={setDados}
                  disabled={[StatusForm.Contrato, StatusForm.Excluindo].includes( statusForm )}
                  erros={erros}
                  mask='tel'
                />

              </Grid>

              <Grid item xs={12} sm={6} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='whatsAPP'
                  label='Whats APP'
                  setState={setDados}
                  onFocus={( _ev ) => {
                    if ( dados.whatsAPP.length === 0 && dados.fone.length > 0 ) {
                      setDados( { ...dados, whatsAPP: dados.fone } )
                    }
                  }}
                  disabled={[StatusForm.Contrato, StatusForm.Excluindo].includes( statusForm )}
                  erros={erros}
                  mask='tel'
                />

              </Grid>

              <Grid item xs={12}>

                <InputText
                  dados={dados}
                  field='email'
                  label='e-mail'
                  setState={setDados}
                  disabled={[StatusForm.Contrato, StatusForm.Excluindo].includes( statusForm )}
                  erros={erros}
                  maxLength={255}
                  tipo="uppercase"
                />

              </Grid>

              <Grid item xs={12}>

                <PesquisarTabela<any>
                  setState={setDados}
                  field='idUnidade'
                  fieldSet='idUnidade'
                  label='Unidade'
                  dados={dados}
                  campoQueryPesquisaID='idUnidade'
                  campoQueryPesquisa='pesquisa'
                  camposRetornoQueryPesquisa='{idUnidade, descricao}'
                  campoLabelQueryPesquisa='descricao'
                  nomeQueryPesquisa='getUnidades'
                  nomeQueryPesquisaID='getUnidadePorId'
                  mensagemPesquisa='Procurando Unidade...'
                  disabled
                  erros={erros}
                />

              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>

                <Condicional condicao={statusForm === StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmarExclusao( dados, mensagemState, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Condicional condicao={[StatusForm.Editando, StatusForm.Incluindo].includes( statusForm )}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmar( dados, mensagemState, statusForm as any, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Condicional condicao={[StatusForm.Editando, StatusForm.Incluindo, StatusForm.Excluindo].includes( statusForm )}>
                  <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => clsCrud.btCancelar()}>Cancelar</Button>
                </Condicional>

              </Grid>

            </Condicional>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <DataTable
                  dados={rsPesquisa}
                  cabecalho={Cabecalho}
                  acoes={[
                    { icone: 'delete', toolTip: 'Excluir', onAcionador: clsCrud.btExcluir },
                    { icone: 'create', toolTip: 'Editar', onAcionador: clsCrud.btEditar },
                    { icone: 'post_add', toolTip: 'Contratos', onAcionador: btContrato }
                  ]}
                />

              </Grid>
            </Condicional>
            <Condicional condicao={[StatusForm.Contrato].includes( statusForm )}>
              <ClientesContratos onCancelar={() => btContratoCancelar()} idCliente={dados.idCliente} />
            </Condicional>

          </Grid>
        </Paper>

      </Container>
    </>
  )
}