// Motivo Cancelamento do Contrato

export interface MotivoCancelamentoContratoTypesInterface {
  idMotivo: string
  descricao: string
}

export enum MotivoCancelamentoContratoType {
  ESTORNO = 'ES',
  ERRO_SISTEMA = 'EE',
  ALTERACAO = 'AL'
}

export const MotivoCancelamentoContratoTypes: Array<MotivoCancelamentoContratoTypesInterface> = [
  { idMotivo: MotivoCancelamentoContratoType.ESTORNO, descricao: 'Estorno' },
  { idMotivo: MotivoCancelamentoContratoType.ERRO_SISTEMA, descricao: 'Erro no Sistema' },
  { idMotivo: MotivoCancelamentoContratoType.ALTERACAO, descricao: 'Alteração' }
]

// Status do Contrato

export interface StatusContratoTypesInterface {
  idStatus: string
  descricao: string
}

export enum StatusContratoType {
  CADASTRADO = 'CD',
  EMITIDO = 'EM',
  CANCELADO = 'CA',
  CONCLUIDO = 'CO'
}

export const StatusContratoTypes: Array<StatusContratoTypesInterface> = [
  { idStatus: StatusContratoType.CADASTRADO, descricao: 'Cadastrado' },
  { idStatus: StatusContratoType.CANCELADO, descricao: 'Cancelado' },
  { idStatus: StatusContratoType.CONCLUIDO, descricao: 'Concluído' },
  { idStatus: StatusContratoType.EMITIDO, descricao: 'Emitido' }
]

// Status da Parcela

export interface StatusParcelaTypesInterface {
  idStatus: string
  descricao: string
}

export enum StatusParcelaType {
  /** Quando é cadastrada no sistema */
  CADASTRADA = 'CD',
  /** Está emitida dentro do Cora */
  EMITIDA = 'EM',
  /** Cancelada no Cora e Dentro do Sistema */
  CANCELADA = 'CA',
  /** Quitada pelo Cora */
  QUITADA = 'PG'
}

export const StatusParcelaTypes: Array<StatusParcelaTypesInterface> = [
  { idStatus: StatusParcelaType.CADASTRADA, descricao: 'Cadastrada' },
  { idStatus: StatusParcelaType.CANCELADA, descricao: 'Cancelada' },
  { idStatus: StatusParcelaType.QUITADA, descricao: 'Quitada' },
  { idStatus: StatusParcelaType.EMITIDA, descricao: 'Emitida' }
]
