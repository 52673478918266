import React, { useState, useContext, useEffect } from 'react'
import { IconButton, Container, Grid, Paper, Typography } from '@mui/material'

import UploadIcon from '@mui/icons-material/Upload'
import InputText from '../../DevComponents/InputText'
import ClsValidacao from '../../Utils/ClsValidacao'
import axios from 'axios'
import { ENDPOINT } from '../../ImportBackend/Config/emDesenvolvimento'
import BackEndAPI from '../../Services/BackEndAPI'
import { ContratoArquivoInterface } from '../../ImportBackend/Interfaces/ContratoInterfaces'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable'

interface PropsInterface {
  idContrato: number | undefined
}

export default function ClientesContratosArquivos ( { idContrato }: PropsInterface ) {

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const [dados, setDados] = useState( { descricao: '' } )
  const [erros, setErros] = useState( {} )

  const [rsArquivos, setRsArquivos] = useState<Array<ContratoArquivoInterface>>( [] )

  const [refresh, setRefresh] = useState( 0 )

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'descricao', dados, erros, retorno )

    setErros( erros )

    return retorno

  }

  const btUpload = () => {

    if ( validarDados() ) {

      let input: HTMLInputElement = document.createElement( 'input' )

      input.type = 'file'
      input.name = 'nomeArquivo'

      input.onchange = () => {

        if ( input.files && idContrato ) {

          const formulario: FormData = new FormData()

          formulario.append( 'nomeArquivo', input.files[0] )
          formulario.append( 'descricao', dados.descricao )
          formulario.append( 'idContrato', idContrato.toString() )
          formulario.append( 'token', getToken() )

          setMensagemState( {
            mensagem: 'Carregando Arquivo...',
            exibir: true,
            exibirBotao: false,
            titulo: 'Aguarde!',
            tipo: 'Loading',
            cb: null
          } )

          axios.post( ENDPOINT.concat( 'uploadContrato' ), formulario, {
            headers: {
              'content-type': 'multipart/form-data'
            } //,
            // onUploadProgress: this.calculaprogressoUpload
          } ).then( rs => {

            if ( !rs.data.ok ) {

              setMensagemState( {
                mensagem: rs.data.mensagem,
                exibir: true,
                exibirBotao: true,
                titulo: 'Erro!',
                tipo: 'error',
                cb: null
              } )

            } else {
              setMensagemState( { ...mensagemState, exibir: false } )
              setDados( { descricao: '' } )
              setRefresh( refresh + 1 )
            }

          } ).catch( () => {
            setMensagemState( {
              mensagem: 'Erro no Servidor!',
              exibir: true,
              exibirBotao: true,
              titulo: 'Consulte Suporte!',
              tipo: 'error',
              cb: null
            } )
          } )

        }


      }

      input.click()

    }

  }

  useEffect( () => {

    const abortController: AbortController = new AbortController()

    const clsApi = new BackEndAPI()

    const query: string = `
      getContratosArquivosPorContrato(idContrato: ${idContrato}) {
        idContratoArquivo
        idContrato
        descricao
        nomeArquivo
      }
    `

    clsApi.query<Array<ContratoArquivoInterface>>( query, 'getContratosArquivosPorContrato', 'Pesquisando Arquivos...', contexto ).then( rs => {
      setRsArquivos( rs )
    } )

    return () => {

      abortController.abort()

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idContrato, refresh] )

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      cabecalho: 'Descrição',
      campo: 'descricao'
    },
    {
      cabecalho: 'Arquivo',
      campo: 'nomeArquivo'
    }
  ]

  const getToken = () => {
    const token = localStorage.getItem( 'token' )
    return token ? token : ''
  }

  const downloadArquivo = ( rsContratoArquivo: ContratoArquivoInterface ) => {

    const formulario: FormData = new FormData()

    formulario.append( 'idContratoArquivo', rsContratoArquivo.idContratoArquivo.toString() )
    formulario.append( 'token', getToken() )

    setMensagemState( {
      mensagem: 'Downloading...',
      exibir: true,
      exibirBotao: false,
      titulo: 'Aguarde!',
      tipo: 'Loading',
      cb: null
    } )

    axios.post( ENDPOINT.concat( 'downloadContratoArquivo' ), formulario, {
      headers: {
        'content-type': 'multipart/form-data'
      },
      responseType: 'blob', // important
      // onUploadProgress: this.calculaprogressoUpload
    } ).then( ( response ) => {

      setMensagemState( { ...mensagemState, exibir: false } )

      // create file link in browser's memory

      const href = URL.createObjectURL( ( new Blob( [response.data] ) ) )

      // create "a" HTML element with href to file & click
      const link = document.createElement( 'a' )
      link.href = href
      link.setAttribute( 'download', rsContratoArquivo.nomeArquivo ) //or any other extension
      document.body.appendChild( link )
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild( link )
      URL.revokeObjectURL( href )
    } )

  }

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          {/* 
            <Grid
            item
            container
            sx={{ borderBottom: "1px solid grey", height: 100 }}
            >
            
          */}
          <Grid container sx={{ alignItems: 'stretch' }}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography component="h5" variant="h5" align="left">
                Arquivos do Contrato
              </Typography>
            </Grid>

            <Grid item xs={11}>

              <InputText
                dados={dados}
                field='descricao'
                label='Descrição Arquivo'
                setState={setDados}
                erros={erros}
                maxLength={30}
                tipo="uppercase"
              />

            </Grid>

            <Grid item xs={1} sx={{ mt: 4, pl: 1 }}            >
              <IconButton onClick={() => btUpload()} >
                <UploadIcon />
              </IconButton>
            </Grid>

            <Grid item xs={12} sx={{ mt: 3 }}>
              <DataTable
                dados={rsArquivos}
                cabecalho={Cabecalho}
                acoes={[
                  { icone: 'download', toolTip: 'Download Arquivo', onAcionador: downloadArquivo },
                ]}
              />

            </Grid>

          </Grid>
        </Paper>
      </Container>
    </>
  )

}